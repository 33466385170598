import { createContext, useState } from "react"
import { initialStateModel, setCurrencyDataModel } from "./CurrencyModel"

const CurrencyContext = createContext(initialStateModel)
const CurrencyProvider = ({ children }) => {
    const [currencyState, setCurrencyState] = useState(initialStateModel)
    const { selectedCurrency, currencyData } = currencyState
    const reset = () => setCurrencyState(initialStateModel)

    const setCurrencies = (data) => {
        const newCurrencyData = setCurrencyDataModel(data)
        setCurrencyState(preVal => ({
            ...preVal,
            currencyData: newCurrencyData,
            selectedCurrency: newCurrencyData.find(x => x.order === 0),
            isCurrencyRef: newCurrencyData.some(x => x.currencyRef)
        }))
    }
    const setCurrency = () => {
        const indexCurrency = currencyData.findIndex(x => x.id === selectedCurrency.id)
        const newIndex = indexCurrency + 1 > currencyData.length - 1 ? 0 : indexCurrency + 1
        setCurrencyState(preVal => ({
            ...preVal,
            selectedCurrency: currencyData[newIndex],
        }))
    }

    const currencyDispatch = {
        reset: reset,
        setCurrencies: setCurrencies,
        setCurrency: setCurrency,
        setCurrencyState: setCurrencyState
    }

    return (
        <CurrencyContext.Provider value={{ currencyState, currencyDispatch }}>
            {children}
        </CurrencyContext.Provider>
    )
}

export { CurrencyContext, CurrencyProvider }