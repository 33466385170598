import { Grid, ListItemButton, Paper } from '@mui/material'
import TaTypography from 'components/typography/TaTypography'
import { memo } from 'react'
import { fnFormatNumb } from 'helper/FormatNumb'
import TaImg from 'components/img/TaImg'
import { fnCalculateHeights } from 'helper/fnCalculateHeights'

const GridCatalogDetail = memo(({
  classes: {
    layoutSelected, layout, header,
    content, footer
  },
  itemData, onFocus, onClick,
  onKeyDown, useImages, selectedKey,
  onHoverGridItem, gridHeight, onLeave
}) => {

  const { uniqueKey, itemName, pricetax, descrip, itemId,
    urlImage, urlImage2, } = itemData

  return (<ListItemButton
    id={`GridItem:${uniqueKey}`}
    sx={{ padding: '0px' }}
    onFocus={() => onFocus(itemData)}
    onClick={() => onClick(itemData)}
    onMouseEnter={() => onHoverGridItem(itemData)}
    disableRipple={true}
    disableTouchRipple={true}
    onKeyDown={(e) => onKeyDown(e, itemData)}
    onMouseLeave={onLeave}>
    <Paper id={`paperGridItem: ${uniqueKey}`} elevation={0} square={true} classes={{ root: (selectedKey === uniqueKey) ? layoutSelected : layout }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper elevation={0} square={true} classes={{ root: header }}>
            <Grid container alignItems={'center'}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'right'} >
                <TaTypography text={fnFormatNumb(pricetax)} fontSize={"12pt"} color={'var(--main-bg-color1)'} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper elevation={0} square={true} classes={{ root: content }}>
            <TaImg id={`gridItemImage: ${uniqueKey}`} url={urlImage} defaultUrl={urlImage2} inStyle={{
              paddingTop: '0px',
              marginLeft: 'auto',
              marginRight: 'auto',
              height: fnCalculateHeights([gridHeight, 19]),
              width: '100%',
            }} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper id={`paperGridItemFooter: ${uniqueKey}`} elevation={0} square={true} classes={{ root: footer }}>
            <Grid container alignItems={'center'} justifyContent={'center'}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TaTypography color={'var(--main-text-color1)'} fontSize={'9pt'} text={itemId} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
                <TaTypography color={'var(--main-text-color1)'} fontSize={'11pt'} text={itemName} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  </ListItemButton>)
})

export default GridCatalogDetail