import { Paper } from '@mui/material';
import React, { useState } from 'react';
import TaGridList from 'components/gridlist/TaGridList'
import useDinamicHeights from 'helper/useDinamicHeights'
import { fnCalculateHeights } from "helper/fnCalculateHeights"
import ItemCatalog from './ItemCatalog'

const bodyCatalogStyles = (height, isTablet, isMobile, width) => ({
    margin: '0px 0px 7px 0px',
    backgroundColor: 'var(--main-bg-color0)',
    height: fnCalculateHeights([height, isMobile ? 284 : 215]),
    overflow: 'auto',
    heightGridList: fnCalculateHeights([height, isMobile ? 287 : 263])
})

const BodyCatalog = ({ itemList, onSelectedItem, selectedCategory }) => {
    const [currentItem, setCurrentItem] = useState();
    const fnOnCloseItem = (data) => {
        if (data) {
            onSelectedItem(data);
        }
        setCurrentItem()
    }
    const fnOnCallback = (data) => setCurrentItem(data)
    const styles = useDinamicHeights(bodyCatalogStyles)

    return (
        // <Paper sx={styles} square={true} elevation={0}>
        <>
            <TaGridList
                onCallback={fnOnCallback}
                data={itemList.filter(x => (selectedCategory === '') || x.categoryId === selectedCategory)}
                gridType={'catalog'}
                height={styles.heightGridList}
                useImages={true} />
            {currentItem && <ItemCatalog item={currentItem} onClose={fnOnCloseItem} />}
        </>
        // </Paper>
    );
};

export default BodyCatalog;