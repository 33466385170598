import { Grid, Pagination, PaginationItem, Paper } from '@mui/material'
import usePagination from 'helper/usePagination'
import GridImgDetail from './GridImgDetail'
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react'
import GridItemDetail from './GridItemDetail'
import GridSimpleDetail from './GridSimpleDetail'
import useWindowSize from 'helper/UseWindowsSize'
import { fnCalculateHeights } from 'helper/fnCalculateHeights'
import { gridListConfigModel, gridListStyles } from './GridListModel'
import GridHomeDetail from './GridHomeDetail'
import GridPaymentMethodDetail from './GridPaymentMethodDetail'
import GridCatalogDetail from './GridCatalogDetail'

const TaGridList = memo(forwardRef(({
  onCallback, data, gridType, onClickHome, isCxc = false,
  useImages = false, height, disabled = false }, ref) => {

  const [{ selectedKey, currentCol, gridHeight, qtyPerPage }, setGridListConfig] = useState(gridListConfigModel())

  const { paginatedData, fnOnChangePage, pageQty, currentPage } = usePagination(data, qtyPerPage)

  const { width, height: viewportHeight, isMobile } = useWindowSize()

  const classes = gridListStyles({
    gridHeight,
    useImages,
    itemListHeight: fnCalculateHeights([height, pageQty > 1 ? 34 : 0]),
    isCat: (gridType === 'category' || gridType === 'paymentMethods'),
    isMobile
  })

  const fnOnChange = (e, value) => fnOnChangePage(value)

  const fnOnClick = (data) => {
    setGridListConfig(preVal => ({ ...preVal, selectedKey: data.uniqueKey }))
    if (onCallback !== undefined) onCallback(data, gridType)
  }

  const fnOnHoverGridItem = (data) => {
    setGridListConfig(preVal => ({ ...preVal, selectedKey: data.uniqueKey }))
  }

  const onLeave = (e) => {
    setGridListConfig(preVal => ({ ...preVal, selectedKey: '' }))
  }

  const fnOnFocus = (data) => setGridListConfig(preVal => ({ ...preVal, selectedKey: data.uniqueKey }))

  useImperativeHandle(ref, () => ({
    setGridListPage: fnOnChangePage
  }), [])

  const fnOnKeyDown = (event, data) => {
    switch (event.key) {
      case 'Enter':
        fnOnClick(data)
        break
      //AGREGAR MAS CASOS PARA LA NAVEGACIÓN CON LAS FLECHAS DIRECCIONALES
      default:
        break
    }
  }

  useEffect(() => {
    let currentGridItemHeight = fnCalculateHeights([height, 34])
    let qtyPerPage, newCol, padMar = 5, gridTileHeight
    switch (gridType) {
      case 'items':
        if (useImages) {
          if (width >= 1920 && currentCol !== 6) {
            gridTileHeight = 140
            newCol = 6
            qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
          } else if (width < 1920 && width >= 1280 && currentCol !== 5) {
            gridTileHeight = 165
            newCol = 5
            qtyPerPage = Math.floor((+currentGridItemHeight.replace("px", "") / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: (12 / newCol), qtyPerPage: qtyPerPage }))
          } else if (width < 1280 && currentCol !== 4) {
            gridTileHeight = 142
            newCol = 4
            qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
          }
        } else {
          gridTileHeight = 142
          if (width >= 1920 && currentCol !== 4) {
            gridTileHeight = 140
            newCol = 4
            qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
          } else if (width < 1920 && width >= 1280 && currentCol !== 3) {
            gridTileHeight = 105
            newCol = 3
            qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
          } else if (width < 1280 && currentCol !== 2) {
            newCol = 2
            qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
          }
        }
        break;
      case 'mobileItems':
        if (useImages) {
          gridTileHeight = 154
          newCol = 2
          setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: 10 }))
        } else {
          gridTileHeight = 114
          newCol = 1
          setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: 10 }))
        }
        break;
      case 'category':
        newCol = 2
        if (useImages) {
          gridTileHeight = 90
          if (width >= 1920 && currentCol !== 6) {
            qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
          } else if (width < 1920 && width >= 1280 && currentCol !== 5) { // 1366 * 768
            qtyPerPage = Math.floor((+currentGridItemHeight.replace("px", "") / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: (12 / newCol), qtyPerPage: qtyPerPage }))
          } else if (width < 1280 && currentCol !== 4) {
            qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
          }
        } else {
          padMar = 10
          gridTileHeight = 57
          qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
          setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
        }
        break
      case 'paymentMethods':
        if (useImages) {
          gridTileHeight = 92
          newCol = isCxc ? 2 : 4
          if (width >= 1920 && currentCol !== 10) {
            qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
          } else if (width < 1920 && width >= 1280 && currentCol !== 8) {
            qtyPerPage = Math.floor((+currentGridItemHeight.replace("px", "") / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: (12 / newCol), qtyPerPage: qtyPerPage }))
          } else if (width < 1280 && currentCol !== 6) {
            newCol = 2
            qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
            setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
          }
        } else {
          gridTileHeight = 56
          newCol = 2
          qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
          setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
        }
        break
      case 'image':
        gridTileHeight = 230
        newCol = 1
        qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
        setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: 12, qtyPerPage: 1 }))
        break
      case 'home':
        gridTileHeight = 80
        if (isMobile === true) {
          newCol = 2
          qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
          setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
        } else {
          newCol = 4
          qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
          setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
        }
        break
      case 'catalog':
        if (isMobile) {
          gridTileHeight = 150
          newCol = 2
          qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
          setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
        } else {
          gridTileHeight = 140
          newCol = 4
          qtyPerPage = Math.floor(((+(currentGridItemHeight.replace("px", ""))) / (gridTileHeight + padMar))) * newCol
          setGridListConfig(preVal => gridListConfigModel({ ...preVal, gridHeight: gridTileHeight, currentCol: Math.floor(12 / newCol), qtyPerPage: qtyPerPage }))
        }
        break
      default:
        break;
    }
  }, [width, currentCol, data, viewportHeight, gridHeight, isMobile, height])

  useEffect(() => fnOnChangePage(1), [pageQty, width])

  return (
    <Paper elevation={0} square={true} sx={{ backgroundColor: 'transparent', padding: '5px' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
          <Paper elevation={0} square={true} id={'paperGridList'} classes={{ root: classes.container }}>
            <Grid container alignItems={'flex-start'} spacing={1}>
              {paginatedData.map(itemData => {
                return <Grid key={`item => ${itemData.uniqueKey}-${itemData.name}`} item xs={currentCol} sm={currentCol} md={currentCol} lg={currentCol} xl={currentCol} >
                  {gridType === 'items' && <GridItemDetail
                    itemData={itemData}
                    classes={classes}
                    onFocus={fnOnFocus}
                    onClick={fnOnClick}
                    useImages={useImages}
                    onKeyDown={fnOnKeyDown}
                    onHoverGridItem={fnOnHoverGridItem}
                    selectedKey={selectedKey}
                    gridHeight={gridHeight}
                    onLeave={onLeave} />}
                  {(gridType === 'category') && <GridSimpleDetail
                    itemData={itemData}
                    classes={classes}
                    onClick={fnOnClick}
                    useImages={useImages}
                    onKeyDown={fnOnKeyDown}
                    onHoverGridItem={fnOnHoverGridItem}
                    selectedKey={selectedKey}
                    gridHeight={gridHeight}
                    onLeave={onLeave} />}
                  {(gridType === 'paymentMethods') && <GridPaymentMethodDetail
                    itemData={itemData}
                    classes={classes}
                    onClick={fnOnClick}
                    useImages={useImages}
                    onKeyDown={fnOnKeyDown}
                    onHoverGridItem={fnOnHoverGridItem}
                    selectedKey={selectedKey}
                    gridHeight={gridHeight}
                    disabled={disabled}
                    onLeave={onLeave} />}
                  {gridType === 'image' && <GridImgDetail
                    itemData={itemData}
                    classes={classes}
                    gridHeight={gridHeight}
                    onClick={fnOnClick}
                    onKeyDown={fnOnKeyDown} />}
                  {gridType === 'home' && <GridHomeDetail
                    itemData={itemData}
                    classes={classes}
                    onFocus={fnOnFocus}
                    onClick={onClickHome}
                    onKeyDown={fnOnKeyDown}
                    onHoverGridItem={fnOnHoverGridItem}
                    selectedKey={selectedKey}
                    onLeave={onLeave} />}
                  {gridType === 'mobileItems' && <GridItemDetail
                    itemData={itemData}
                    classes={classes}
                    onFocus={fnOnFocus}
                    onClick={fnOnClick}
                    useImages={useImages}
                    onKeyDown={fnOnKeyDown}
                    onHoverGridItem={fnOnHoverGridItem}
                    selectedKey={selectedKey}
                    gridHeight={gridHeight}
                    onLeave={onLeave} />}
                  {gridType === 'catalog' && <GridCatalogDetail
                    itemData={itemData}
                    classes={classes}
                    onFocus={fnOnFocus}
                    onClick={onCallback}
                    onKeyDown={fnOnKeyDown}
                    onHoverGridItem={fnOnHoverGridItem}
                    selectedKey={selectedKey}
                    onLeave={onLeave} />}
                </Grid >
              })}
            </Grid >
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height: '34px' }} hidden={pageQty === 1}>
          <Pagination
            count={pageQty}
            classes={{ root: classes.pagination }}
            page={currentPage}
            onChange={fnOnChange}
            boundaryCount={(gridType === 'category' || gridType === 'paymentMethods') ? 0 : 1}
            siblingCount={1}
            defaultPage={1}
            disabled={disabled}
            showFirstButton={gridType !== 'image'}
            showLastButton={gridType !== 'image'}
            size={'small'}
            renderItem={(itemData) => <PaginationItem
              {...itemData}
              classes={{ root: classes.paginationItem, selected: classes.paginationItemSelected }}
            />}
          />
        </Grid>
      </Grid >
    </Paper >
  )
}))

export default TaGridList