import { memo } from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const Cube = memo(({ sx }) => {
  return (
    <SvgIcon sx={sx} viewBox="0 0 16 16">
      <path d="M7.81635 1.03383C7.93558 0.98612 8.0686 0.986119 8.18784 1.03383L14.6879 3.63465C14.8778 3.7106 15.0022 3.89447 15.0022 4.09892C15.0022 4.11674 15.0013 4.13441 14.9994 4.15186C14.9996 4.15818 14.9998 4.16452 14.9998 4.1709L14.9998 11.8691C14.9998 12.0727 14.8763 12.256 14.6876 12.3325L8.19463 14.9649C8.12932 14.9913 8.06168 15.003 7.99544 15.0015C7.93136 15.0017 7.86696 14.9896 7.80585 14.9649L1.31288 12.3325C1.12419 12.256 1.00073 12.0727 1.00073 11.8691L1.00073 4.1709C1.00073 4.15527 1.00145 4.1398 1.00285 4.12454C1.00242 4.11604 1.0022 4.1075 1.0022 4.09892C1.00218 3.89447 1.12663 3.7106 1.31645 3.63465L7.81635 1.03383ZM8.49435 13.7643L13.9998 11.5323L13.9998 4.83827L8.50018 7.03736L8.49435 13.7643ZM2.00073 4.83672L2.00073 11.5323L7.49435 13.7595L7.50018 7.03585L2.00073 4.83672ZM8.0021 2.03659L2.84847 4.09872L8.00208 6.15956L13.1559 4.09872L8.0021 2.03659Z" />
    </SvgIcon>
  )
})

export default Cube