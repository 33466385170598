import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

Number.prototype.toFixedNoRounding = function (n) {
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?")
  const newValue = this.toFixed(4)
  const a = newValue.toString().match(reg)[0]
  const dot = a.indexOf(".")
  if (dot === -1) {
    return a + "." + "0".repeat(n)
  }
  const b = n - (a.length - dot) + 1
  return b > 0 ? (a + "0".repeat(b)) : a
}

Number.prototype.formatNumb = function (n, x) {
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
  return (this.toFixed(n)).replace(new RegExp(re, 'g'), '$&,')
}

Number.prototype.round = function (rlength) {
  let newValue = isNaN(this) ? 1 : this
  var newnumber = Math.round(newValue * Math.pow(10, rlength)) / Math.pow(10, rlength)
  return newnumber
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date
}

ReactDOM.render(<React.StrictMode><App /></React.StrictMode>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
