import axios from 'axios';
import { saveAs } from 'file-saver'

var baseUrl = "https://127.0.0.1:5000",
    // bearerToken = "",
    authBasicHeader = {
        username: "",
        password: "",
    },
    timeout = 60000;

export const setBaseUrl = (value) => baseUrl = value
export const setBasicAuth = (user, pass = "") => {
    authBasicHeader = {
        username: user,
        password: pass
    }
}
export const fnGetUrl = async (apiRequest, lang = 'ES') => {
    const urlEndpoint = `${newUrl(baseUrl, apiRequest.baseUrl)}${apiRequest.endpoint}`
    if (apiRequest.useBasic) {
        return await axios.get(urlEndpoint, {
            auth: {
                ...(apiRequest.basicAuth ?? authBasicHeader)
            },
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout
        })
            .then(response => response)
            .catch(error => handleError(error, lang))
    } else {
        return await axios.get(urlEndpoint, {
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout,
        })
            .then(response => response)
            .catch(error => handleError(error, lang))
    }

}

export const fnPostUrl = async (apiRequest, lang) => {
    const urlEndpoint = `${newUrl(baseUrl, apiRequest.baseUrl)}${apiRequest.endpoint}`
    // console.log('authBasicHeader => ', authBasicHeader)
    if (apiRequest.body && process.env.NODE_ENV === 'development') {
        console.log('body: ', JSON.stringify(apiRequest.body))
    }

    if (apiRequest.useBasic) {
        return await axios.post(urlEndpoint, apiRequest.body, {
            auth: {
                ...(apiRequest.basicAuth ?? authBasicHeader)
            },
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout
        })
            .then(response => response)
            .catch(error => handleError(error, lang))
    } else {
        return await axios.post(urlEndpoint, apiRequest.body, {
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout
        })
            .then(response => response)
            .catch(error => handleError(error, lang))
    }
}

export const fnPutUrl = async (apiRequest) => {
    const urlEndpoint = `${newUrl(baseUrl, apiRequest.baseUrl)}${apiRequest.endpoint}`
    if (apiRequest.body && process.env.NODE_ENV === 'development') {
        console.log('body: ', JSON.stringify(apiRequest.body))
    }

    if (apiRequest.useBasic) {
        return await axios.put(urlEndpoint, apiRequest.body, {
            auth: {
                ...(apiRequest.basicAuth ?? authBasicHeader)
            },
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout
        })
            .then(response => response)
            .catch(error => handleError(error))
    } else {
        return await axios.put(urlEndpoint, apiRequest.body, {
            timeout: apiRequest.timeout ? apiRequest.timeout : timeout
        })
            .then(response => response)
            .catch(error => handleError(error))
    }
}

const handleError = (error, lang = 'ES') => {
    console.error('handleApiError => ', error)
    if (error.toString() === "Error: Network Error") {
        return {
            status: 504,
            data: {
                message: langResponse(lang, 504),
            }
        }
    }
    if (error.response) {
        return error.response
    } else {
        if (error.toString().includes('timeout')) {
            return {
                status: 408,
                data: {
                    message: langResponse(lang, 408),
                }
            }
        }
        return {
            status: 503,
            data: {
                message: langResponse(lang, 503)
            }
        }
    }
}

const langResponse = (lang, status) => 'No hay conexión contra el servicio, por favor reintente nuevamente'

const newUrl = (baseUrl, url) => (url) ? url : baseUrl

export const fnGetBaseUrl = () => baseUrl

export const fnDownloadFile = async (apiRequest) => {
    const urlEndpoint = `${newUrl(baseUrl, apiRequest.baseUrl)}${apiRequest.endpoint}`
    const platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
    const isIOs = ((/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (platform === 'MacIntel' && navigator.maxTouchPoints > 1))

    return await axios.get(urlEndpoint, { responseType: 'blob' })
        .then(response => {
            if (isIOs === false) saveAs(`${urlEndpoint}`, apiRequest.fileName)
        })
        .catch()
}