import localForage from 'localforage'
import { LOCALFORAGE_DBNAME } from './LocalforageConstants'

export const dbSimplit = localForage.createInstance({
  name: LOCALFORAGE_DBNAME
})

export const postLocalDB = (TableName, ObjectData) => {
  return dbSimplit.setItem(TableName, ObjectData).then(value => {
    return setResponseForage(false, '', value)
  }).catch(err => {
    return setResponseForage(true, err, [])
  })
}

export const setResponseForage = (error, message, data = null) => {
  const responseForage = {
      error: error,
      message: message,
      data: data
  }
  return responseForage
}

export const removeItemLocalDB = (keyToDel, requestData, post = true) => {
  return dbSimplit.removeItem(keyToDel).then(() => {
      if(post){
          return postLocalDB(keyToDel, requestData)
      }
  }).catch( err => {
    console.log('aja3')
      setResponseForage(true, err, [])
  })
}

export const chkIfExists = (key, lic) => {
  return dbSimplit.getItem(key).then(async (value) => {
      if(value !== null && value !== undefined){
          const currentIndex = value.findIndex(x => x.lic === lic)
          return (currentIndex > -1)
      }
      return false
  })
}