import React, { useEffect, useRef, useState } from 'react'
import { Grid } from '@mui/material'
import { initialModel } from './CatalogModel'
import { getCatalogInfo, getCustomerInfo } from './CatalogApi'
import { Buffer } from 'buffer'
import HeaderCatalog from './components/HeaderCatalog'
import BodyCatalog from './components/BodyCatalog'
import { v4 as uuid } from "uuid"
import CatalogCategories from './components/CatalogCategories'
import FooterBar from 'views/Home/components/FooterBar'
import useWindowSize from 'helper/UseWindowsSize'
import TaStepper from 'components/stepper/TaStepper'

const Catalog = ({ token, customer, phone }) => {

    const stepperRef = useRef()

    const [purchase, setPurchase] = useState(initialModel)
    const [isLoading, setIsLoading] = useState(false)
    const [credentials, setCredentials] = useState()
    const { isMobile } = useWindowSize()
    const fnClearOrder = () => setPurchase(preVal => ({ ...preVal, orderList: [] }))
    const fnOnDeleteItem = (uniqueKey) => {
        setPurchase(preVal => ({ ...preVal, orderList: preVal.orderList.filter(x => x.uniqueKey !== uniqueKey) }))
        if (purchase.orderList.filter(x => x.uniqueKey !== uniqueKey).length === 0) {
            stepperRef.current.setStep()
        }
    }
    const fnOnSelectCategory = (category) => {
        setPurchase(preVal => ({ ...preVal, selectedCategory: category.categoryId }))
        stepperRef.current.nextStep()
    }
    const fnOnSelectedItem = (item) => setPurchase(preVal => ({ ...preVal, orderList: preVal.orderList.concat({ ...item, uniqueKey: uuid() }) }))
    useEffect(() => {
        const fnGetCustomerInfo = async () => {
            let response
            setIsLoading(true)
            try {
                response = await getCatalogInfo(Buffer.from(customer, "base64").toString())
                if (response.status === 200 && response.data.errorCode === 0) {
                    let newUrl = Buffer.from(response.data.data.catalogURL, "base64").toString()
                    let newToken = response.data.data.tokenCloud
                    response = await getCustomerInfo(newUrl, newToken)
                    console.log(response)
                    if (response.status === 200 && response.data.errorCode === 0) {
                        setPurchase(preVal => ({
                            ...preVal,
                            categoryList: response.data.data.categories.map(x => ({ ...x, name: x.categoryName, uniqueKey: uuid() })),
                            itemList: response.data.data.items.map(x => ({ ...x, uniqueKey: uuid(), urlImage: `${newUrl}${x.urlImage}` })),
                            customerName: response.data.data.company[0].descrip,
                        }))
                        setCredentials({
                            customer: newUrl,
                            token: newToken
                        })
                    }
                }
            } catch (error) {
                console.error('Error getting customer info')
            }

            setIsLoading(false)
        }
        fnGetCustomerInfo()
    }, [])

    return (
        <Grid container alignContent='center' alignItems='center'>
            <Grid item xs={false} sm={1} md={3} lg={3} xl={3}></Grid>
            <Grid item xs={12} sm={10} md={6} lg={6} xl={6} align="center">
                <HeaderCatalog customerName={purchase.customerName}
                    orderList={purchase.orderList}
                    onDeleteItem={fnOnDeleteItem}
                    onClearOrder={fnClearOrder}
                    dataCustomer={
                        {
                            ...credentials,
                            phone: phone
                        }
                    } />
            </Grid>
            <Grid item xs={false} sm={1} md={3} lg={3} xl={3} />
            {isMobile
                ? <TaStepper
                    isMobile={isMobile}
                    onlyMobile={true}
                    labelArray={['', '']}
                    topMobileStepper={true}
                    ref={stepperRef}
                    spacing={0}>
                    <CatalogCategories categoryList={purchase?.categoryList ?? []} onSelectCategory={fnOnSelectCategory} />
                    <BodyCatalog itemList={purchase.itemList} onSelectedItem={fnOnSelectedItem} selectedCategory={purchase.selectedCategory} />
                </TaStepper>
                : <>
                    <Grid item xs={false} sm={1} md={1.5} lg={1.5} xl={1.5} />
                    <Grid item xs={false} sm={1} md={3} lg={3} xl={3}>
                        <CatalogCategories categoryList={purchase?.categoryList ?? []} onSelectCategory={fnOnSelectCategory} />
                    </Grid>
                    <Grid item xs={12} sm={10} md={6} lg={6} xl={6} align="center">
                        <BodyCatalog itemList={purchase.itemList} onSelectedItem={fnOnSelectedItem} selectedCategory={purchase.selectedCategory} />
                    </Grid>
                    <Grid item xs={false} sm={1} md={1.5} lg={1.5} xl={1.5} />
                </>}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                <FooterBar />
            </Grid>
        </Grid>
    )
}

export default Catalog