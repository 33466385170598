import { useState, memo, useEffect } from 'react'
import { List, Paper } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component';

const TaList = memo(({ id, children, elevation, height, backgroundColor, padding, infinityScroll = true, maxList = 15, selectedId = '' }) => {
  const [infinityList, setInfinityList] = useState({
    list: [],
    originalList: []
  })
  const fnFetchData = () => {
    try {
      setInfinityList(preVal => ({
        ...preVal,
        list: preVal?.list?.concat(preVal.originalList.slice(preVal.list.length, (preVal.originalList.length - preVal.list.length) > maxList
          ? (preVal.list.length + maxList)
          : (preVal.originalList.length - preVal.list.length))),
      }))
      document.getElementById(selectedId)?.scrollIntoView({ block: 'end' })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (infinityScroll) {
      try {
        setInfinityList({
          list: children[0].slice(0, children[0].length < maxList ? children[0].length : maxList),
          originalList: children[0]
        })
      } catch {
        setInfinityList({
          list: children?.slice(0, children?.length < maxList ? children?.length : maxList),
          originalList: children
        })
      }
    }
  }, [children])

  return (
    <Paper
      id={`paper:${id}`}
      elevation={elevation}
      square={true}
      sx={{
        height: (height && !infinityScroll) ? height : undefined,
        backgroundColor: backgroundColor && backgroundColor,
        padding: padding ? padding : '5px, 15px, 5px, 5px',
        overflow: 'auto',
        overflowX: 'hidden',
        scrollBehavior: 'smooth'
      }}>
      <List id={id} component='nav'>
        {
          infinityScroll
            ? <InfiniteScroll
              next={fnFetchData}
              dataLength={infinityList?.list?.length}
              hasMore={infinityList?.originalList?.length > infinityList?.list?.length}
              style={{ overflow: 'auto', overflowX: 'hidden' }}
              height={height && height}>
              {infinityList?.list}
            </InfiniteScroll>
            : children
        }
      </List>
    </Paper>
  )
})

export default TaList