import { memo } from 'react'
import { Badge } from '@mui/material'
import propTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import badgeIcons from './BadgeIcons'

const badgeStyles = makeStyles({
  standard: {
    width: props => props.badgeWidth,
    minWidth: '20px',
    padding: '2px',
    top: props => props.top,
    right: '5%',
    color: props => props.fontColor,
    backgroundColor: props => props.badgeColor,
  },
  root: {
    width: props => props.width
  }
})

const TaBadge = memo(({ id, children, icon, invisible, onClick = () => null, extContent = undefined, badgeColor = '#FFF', fontColor = '#666666',
  width, badgeWidth = '20px', top = '75%' }) => {

  const content = extContent ?? badgeIcons(icon)

  const classes = badgeStyles({ badgeColor, fontColor, width, badgeWidth, top })

  const fnOnClick = (e) => { if (!invisible) onClick(e.target.id) }

  return (
    <Badge
      id={id}
      badgeContent={content}
      variant={'standard'}
      classes={{ standard: classes.standard, root: classes.root }}
      invisible={invisible}
      onClick={fnOnClick}>
      {children}
    </Badge>
  )
})

TaBadge.propTypes = {
  children: propTypes.object.isRequired,
  content: propTypes.any,
  invisible: propTypes.bool,
  id: propTypes.string
}

TaBadge.defaultProps = {
  id: '',
  invisible: false,
  icon: 'setting'
}

export default TaBadge