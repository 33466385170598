import { fnGetUrl, fnPostUrl } from "functions/Api"
import { isSpCloud } from "../../constants/Global"
import {postOrderModel} from "./CatalogModel"

export const getCustomerInfo = (baseUrl, token) => fnGetUrl({
    baseUrl: baseUrl,
    endpoint: `/api/Cloud/Catalog?isCloud=${isSpCloud}`,
    useBasic: true,
    basicAuth: { username: token, password: '' },
})

export const getCatalogInfo = ( nickname) => fnGetUrl({
    baseUrl: 'https://cloudapi.simplitpos.com:8400',
    endpoint: `/api/Register/InfoCatalog?nickname=${nickname}`,
})

export const postOrder = (baseUrl, token, phone, totalAmount, data) => fnPostUrl({
    baseUrl: baseUrl,
    endpoint: `/api/Cloud/Orders`,
    useBasic: true,
    basicAuth: { username: token, password: '' },
    body: postOrderModel(phone, totalAmount, data),
});