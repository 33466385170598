import { makeStyles } from "@mui/styles"
import { fnCalculateHeights } from "helper/fnCalculateHeights"

export const gridListStyles = makeStyles({
  layout: {
    borderRadius: "5px",
    borderStyle: "solid",
    borderColor: "var(--main-bg-color1)",
    cursor: 'pointer',
    fontFamily: 'Archivo',
    fontSize: "14pt",
    color: "var(--main-text-color0)",
    overflow: 'hidden',
    width: '100%',
    height: props => props.gridHeight
  },
  layoutSelected: {
    borderRadius: "5px",
    borderStyle: "solid",
    borderColor: "var(--main-bg-color3) !important",
    cursor: 'pointer',
    fontFamily: 'Archivo',
    fontSize: "14pt",
    color: "var(--main-text-color1) !important",
    overflow: 'hidden',
    width: '100%',
    height: props => props.gridHeight
  },
  container: {
    height: props => props.itemListHeight,
    overflow: 'auto'
  },
  header: {
    height: '20px',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  content: {
    overflow: props => props.useImages ? undefined : 'hidden',
    padding: props => props.useImages ? '0px' : '0px 5px 0px 5px',
    height: props => props.useImages
      ? fnCalculateHeights([props.gridHeight, 20, 45])
      : fnCalculateHeights([props.gridHeight, 20, 26]),
    backgroundColor: 'transparent',
  },
  footer: {
    backgroundColor: 'var(--main-bg-color11)',
    // height: '40px',
    padding: '5px 5px 5px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  outlined: {
    fontFamily: 'Archivo',
    fontSize: "14pt",
    color: "var(--main-text-color0)",
  },
  pagination: {
    "& > *": {
      margin: '5px 0px, 5px, 0px',
      justifyContent: "center",
      display: 'flex'
    },
  },
  paginationItem: {
    fontFamily: 'Archivo',
    fontSize: props => props.isMobile ? '14pt' : "11pt",
    color: "var(--main-text-color0)",
  },
  paginationItemSelected: {
    fontFamily: 'Archivo',
    fontSize: props => props.isMobile ? '14pt' : "11pt",
    color: "var(--main-bg-color0)",
    backgroundColor: 'var(--main-bg-color3) !important'
  },
  layoutImage: {
    borderRadius: "5px",
    borderStyle: "solid",
    borderColor: "var(--main-bg-color1)",
    cursor: 'pointer',
    fontFamily: 'Archivo',
    fontSize: "14pt",
    color: "var(--main-text-color0)",
    overflow: 'hidden',
    minHeight: props => props.gridHeight,
  },
})

export const gridListConfigModel = (data) => ({
  selectedKey: data?.selectedKey ?? '',
  currentCol: data?.currentCol ?? 0,
  gridHeight: data?.gridHeight ?? 170,
  qtyPerPage: data?.qtyPerPage ?? 0,
  gridItemHeight: data?.gridItemHeight ?? '',
  infoHeight: data?.infoHeight ?? 140
})


try {
  // si falla no te jode
} catch (error) {
  // no emites nada
}