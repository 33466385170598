import { makeStyles } from '@mui/styles';

export const listItemStyles = makeStyles({
    root: {
        height: props => props.height ?? undefined,
        minHeight: '50px',
        backgroundColor: props => props.orderList ? "var(--main-list-color2)" : "var(--main-list-color0)",
        marginTop: "5px",
        marginBottom: "10px",
        padding: "5px",
        paddingLeft: "15px",
        borderRadius: "5px",
        fontFamily: 'Archivo',
        fontSize: props => props.fontSize,
        color: "var(--main-text-color0)",
        cursor: 'pointer',
    },
    selected: {
        backgroundColor: props => props.orderList || props.isAlternativeColor ? "var(--main-bg-color3) !important" : "var(--main-bg-color1) !important",
        color: "var(--main-text-color1) !important"
    },
    disabled: {}
})