import { Grid, ListItemButton, Paper } from "@mui/material"
import TaImg from "components/img/TaImg"
import { memo } from "react"

const GridImgDetail = memo(({ itemData, onClick, onKeyDown, classes: { layoutImage }, gridHeight }) => {
  return (
      <ListItemButton
        id={`ListItemButton:${itemData.uniqueKey}`}
        sx={{ padding: '0px' }}
        onClick={() => onClick(itemData)}
        onKeyDown={(e) => onKeyDown(e, itemData)}>
        <Grid container alignItems={'center'} justifyContent={'center'}>
            <Paper elevation={0} square={true} classes={{ root: layoutImage }}>
              <TaImg url={itemData.url} inStyle={{ paddingTop: '0px', height: gridHeight, width: '100%', }} />
            </Paper>
        </Grid>
      </ListItemButton>


  )
})

export default GridImgDetail