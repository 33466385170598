export const TUTORIAL = '/'
export const LOGIN = '/Login'
export const WEBLOGIN = '/WebLogin'
export const HOME = '/Home'
export const CONFIGURATION = '/Configuration'
export const REGISTER_USERS = '/RegisterUsers'
export const SALES = '/Sales'
export const CASH_REGISTER_CLOSURE = '/CashRegisterClosure'
// export const ORDER_TOUCH = '/OrderTouch'
export const SALES_MOBILE = '/Mobile'
export const FISCAL_PRINTER = '/FiscalPrinter'
export const RECOVER_PASSWORD = '/RecoverPassword'
export const INVENTORY = '/Inventory'
export const DASHBOARD = '/Dashboard'
export const CATALOG = '/Catalog'