import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const EraseIcon = ({ sx }) => {
    return (
        <SvgIcon sx={sx} viewBox="-1 -2 11 11">
            <path 
                className="st0" 
                d="M1.2,5.4C1.2,5.8,0.9,6,0.6,6S0,5.8,0,5.4c0-0.3,0.3-0.6,0.6-0.6S1.2,5.1,1.2,5.4z M2.3,3.5h3.2c0.4-0.7,1.1-1.2,2-1.2
                c0,0,0,0,0,0H2.3c-0.3,0-0.6,0.3-0.6,0.6S1.9,3.5,2.3,3.5z M0.6,2.3C0.3,2.3,0,2.5,0,2.9s0.3,0.6,0.6,0.6s0.6-0.3,0.6-0.6
                S0.9,2.3,0.6,2.3z M2.3,1.2h5.2C7.8,1.2,8,0.9,8,0.6S7.8,0,7.4,0H2.3C1.9,0,1.7,0.3,1.7,0.6S1.9,1.2,2.3,1.2z M9.8,4.6
                c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3C8.8,2.3,9.8,3.4,9.8,4.6z M8.9,4.6c0-0.1-0.1-0.3-0.3-0.3H6.5
                c-0.1,0-0.3,0.1-0.3,0.3c0,0.1,0.1,0.3,0.3,0.3h2.2C8.8,4.9,8.9,4.8,8.9,4.6z M0.6,0C0.3,0,0,0.3,0,0.6s0.3,0.6,0.6,0.6
                s0.6-0.3,0.6-0.6S0.9,0,0.6,0z M2.3,4.8c-0.3,0-0.6,0.3-0.6,0.6C1.7,5.8,1.9,6,2.3,6h3.4C5.4,5.7,5.2,5.3,5.2,4.8H2.3z"/>
        </SvgIcon>
    )
}

export default EraseIcon