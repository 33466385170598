import { Paper } from "@mui/material"
import useDinamicHeights from "helper/useDinamicHeights"
import { useState, memo, useEffect } from "react"
import { isCatalog, isSpCloud } from "constants/Global"

const footerStyles = (height, isTablet, isMobile) => ({
  // footerContainerStyles: ,
  logoSimplitPOSStyle: {
    // width: '90px',
    height: '100%'
  },
  logoSaintStyle: {
    // width: !isMobile ? '220px' : '175px',
    height: '75%'
  }
})

const FooterBar = memo(({ isOpenLocalForageAdmin, fnSetBoolOptions }) => {

  const { footerContainerStyles, logoSimplitPOSStyle, logoSaintStyle } = useDinamicHeights(footerStyles)

  const [clickedCount, setClickedCount] = useState(0)

  const fnOnClick = () => setClickedCount(preValue => preValue + 1)

  useEffect(() => (clickedCount === 10) && fnSetBoolOptions('isOpenLocalForageAdmin'), [clickedCount])

  useEffect(() => !(isOpenLocalForageAdmin) && setClickedCount(0), [isOpenLocalForageAdmin])

  return (
    <Paper elevation={0} square={true} sx={{
      backgroundColor: 'var(--main-bg-color1)',
      overflow: 'hidden',
      // padding: '10px 0px 10px 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      position: 'relative',
      height: '60px'
    }} >
      {!isSpCloud && !isCatalog && <img src="https://app.simplitpos.com/img/logoSaintNew.png" alt='' style={logoSaintStyle} />}
      <img src="https://app.simplitpos.com/img/NewTA-White.png" alt='' style={logoSimplitPOSStyle} onClick={fnOnClick} />
    </Paper>
  )
})

export default FooterBar
