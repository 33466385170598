import { memo } from 'react'
import { Typography } from "@mui/material/"

const TaTypography = memo(({ fontFamily, fontSize, type, color,
  text, align, width, height,
  display, isTitle, noWrap, children, maxWidth,
  onClick, id, }) => (<Typography
    id={id}
    onClick={(onClick) && onClick}
    noWrap={noWrap && noWrap}
    align={(isTitle) ? 'center' : align}
    sx={{
      fontFamily: fontFamily,
      margin: "2px",
      fontSize: (isTitle) ? "18pt" : fontSize,
      color: color ?? 'var(--main-text-color0)',
      height: height,
      width: width,
      maxWidth: maxWidth,
      // textOverflow: "ellipsis",
      lineHeight: "1.0",
    }}
    display={display}>
    {(!children) ? text : children}
  </Typography>))

export default TaTypography