import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

const TaImg = memo(({ id, url, defaultUrl, inHeight, inStyle, cols = 1, text }) => {
    const [width, setWidth] = useState("");
    const [height, setHeight] = useState(inHeight);
    const [padLeft, setPadLeft] = useState(`calc((100% - ${width}px )/2)`)
    const imgElement = React.useRef(null);
    const fnOnLoading = () => {
        if (imgElement.current.naturalHeight) {
            setHeight(inHeight)
            setWidth(imgElement.current.width)
            if (cols === 2) {
                setPadLeft(`0px`)
            } else {
                // setPadLeft(`calc((100% - ${imgElement.current.width}px )/2)`)
            }
        }
    }
    return <img
        id={id}
        onLoad={fnOnLoading}
        style={(inStyle) ? inStyle : { paddingTop: "25px", paddingLeft: padLeft, height: height + 'px', marginLeft: 'auto', marginRight: 'auto', align: "middle" }}
        ref={imgElement}
        src={url}
        alt={text} />
});

TaImg.propTypes = {
    url: PropTypes.string,
    defaultUrl: PropTypes.string,
    text: PropTypes.string,
};
TaImg.defaultProps = {
    url: '',
    defaultUrl: '',
    text: '',
};

export default TaImg;