import React from 'react';
import TaListItem from "components/list/TaListItem";
import { Grid } from "@mui/material";
import TaTypography from 'components/typography/TaTypography'
import TaFab from 'components/fab/TaFab'
import { fnFormatNumb } from 'helper/FormatNumb'

const OrderListDetail = ({ item, onDeleteItem }) => {

    const { itemId, itemName, isExempt, qty, pricetax, amount, uniqueKey } = item
    return (
        <TaListItem orderList={true}>
            <Grid container alignContent="center" alignItems="center" spacing={0}>
                <Grid item xs={12} sm={11} md={11} lg={11} xl={11}>
                    <Grid container alignContent="flex-start" alignItems="flex-start" spacing={0}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TaTypography text={itemId} fontSize="10pt" color={"var(--main-text-color1)"} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TaTypography text={`x${qty} ${itemName} (${isExempt === 1 ? 'E' : 'G'})`} fontSize="10pt" color={"var(--main-text-color1)"} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TaTypography text={fnFormatNumb(pricetax)} fontSize="10pt" color={"var(--main-text-color1)"} />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                            <TaTypography text={fnFormatNumb(amount)} fontSize="10pt" color={"var(--main-text-color1)"} align='right' />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1} align='right'>
                    <TaFab id={'btnDeleteItem'} icon={'exit'} color='red' onClick={() => onDeleteItem(uniqueKey)} miniFab={true} />
                </Grid>
            </Grid>
        </TaListItem>
    );
};

export default OrderListDetail;