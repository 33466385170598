import { makeStyles } from "@mui/styles";

export const whiteStyles = makeStyles({
    input:
    {
        textAlign: props => props.textAlign ?? 'left',
        fontFamily: "Archivo",
        fontSize: "12pt",
        color: 'var(--main-text-color1)',
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    underline: {
        '&:focus': {
            borderBottom: props => props.noUnderLine ? 'transparent' : '1px solid var(--main-bg-color3)'
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: props => props.noUnderLine ? 'transparent' : '2px solid var(--main-bg-color3)',
        },
        '&:before': {
            borderBottom: props => props.noUnderLine ? 'transparent' : '2px solid var(--main-bg-color0)',
        },
        '&:after': {
            borderBottom: props => props.noActiveUnderLine ? 'transparent' : '2px solid var(--main-bg-color3)',
        }
    },
})

export const whiteLabelStyles = makeStyles({
    root: {
        fontFamily: "Archivo",
        fontSize: "12pt",
        color: props => props.color ?? 'var(--main-text-color1)',
        '&:hover': {
            backgroundColor: 'transparent !important',
            color: props => props.color ?? 'var(--main-bg-color0)',
        },
        '&$focused': {
            color: 'var(--main-bg-color0)',
            margin: '0px !important',
        },
        '&$.Mui-error': {
            color: 'var(--main-bg-color9)'
        },
        textAlign: props => props.textAlign ?? 'left'
    },
    focused: {
        color: 'var(--main-bg-color9)',
        fontFamily: "Archivo",
        fontSize: "12pt",
        backgroundColor: 'transparent !important',
        '&$focused': {
            backgroundColor: 'transparent !important',
            color: props => props.color ?? 'var(--main-bg-color0)',
        },
        textAlign: props => props.textAlign ?? 'left'
    }
});
