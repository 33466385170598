import { v4 as uuid } from 'uuid'

const selectedCurrencyModel = {
    id: 1,
    name: 've',
    idCountry: '',
    symbol: '',
    order: 1,
    factor: 1,
    typeDecimal: 0,
    currencyRef: false,
}

export const initialStateModel = {
    currencyData: [],
    selectedCurrency: selectedCurrencyModel,
    isCurrencyRef: false,
}

export const setCurrencyDataModel = (data) => data.map((x, i) => ({
    ...x,
    order: x.order === -1 ? i : x.order,
    uniqueKey: uuid(),
    typeCalc: (x?.typeCalc) ? x?.typeCalc !== "" ? x?.typeCalc : '/' : '/',
    currencyRef: x?.currencyRef ?? false
})).sort((a, b) => a.order - b.order)
