import { fnFormatNumb } from "helper/FormatNumb"
import currency from "currency.js";

export const fnMathCalculation = (value1, value2, operator, precision = 2) => {
    let response = 0
    try {
        switch (operator) {
            case "+":
                response = currency(value1, { precision: precision }).add(value2).value
                break;
            case "-":
                response = currency(value1, { precision: precision }).subtract(value2).value
                break;
            case "*":
                response = currency(value1, { precision: precision }).multiply(value2).value
                break;
            case "/":
                response = currency(value1, { precision: precision }).divide(value2).value
                break;
            default:
        }
    } catch (error) {
        console.error('fnMathCalculation => ', error)
    }
    return {
        amount: +response,
        amountLabel: fnFormatNumb(+response),
        amountNoFixed: +response.toFixedNoRounding(2),
        amountNoFixedLabel: fnFormatNumb(+response.toFixedNoRounding(2))
    }
}

export const fnMathCalculationArray = (valueArray, operator = "+", precision = 2) => {
    try {
        let response = 0,
            newPrecision = 0,
            totalDecimals = 0,
            totalDecimalsMult = 0,
            calculation = 0,
            calculationMult = 0;
        valueArray.forEach(x => {
            try {
                newPrecision = x.toString().split(".")[1].length ?? 0
            } catch {
                newPrecision = 0
            }

            totalDecimals = totalDecimals === 0
                ? newPrecision
                : newPrecision > totalDecimals
                    ? newPrecision
                    : totalDecimals
        })

        totalDecimalsMult = totalDecimals * 2
        calculation = +("1".padEnd((totalDecimals + 1), "0"))
        calculationMult = +("1".padEnd((totalDecimalsMult + 1), "0"))
        const newArrayValue = valueArray.map(x => (x * (calculation === 0 ? 1 : calculation)).round(totalDecimals))
        switch (operator) {
            case "+":
                response = (newArrayValue.reduce((amountResp, value) => amountResp + value, 0)) / calculation
                break;
            case "-":
                response = (newArrayValue[0] - newArrayValue[1]) / calculation
                break;
            case "*":
                response = newArrayValue.reduce((amountResp, value) => amountResp * value, 0) / calculationMult
                break;
            case "/":
                response = newArrayValue.reduce((amountResp, value) => amountResp / value, 0)
                break;
            default:
                response = 0
        }
        return {
            amount: +response.round(precision),
            amountLabel: (response.round(2)).formatNumb(2),
            amountNoFixed: +response.toFixedNoRounding(2),
            amountNoFixedLabel: (+response.toFixedNoRounding(2)).formatNumb(2)
        }
    } catch (error) {
        console.error('fnMathCalculationArray => ', error);
        return {
            amount:0,
            amountLabel: 0,
            amountNoFixed: 0,
            amountNoFixedLabel: 0
        }
    }

}

export const fnDecimalToRight = (value) => {
    if (value.substring(value.length - 1, value.length) === "0") {
        value = value.substring(0, value.length - 1)
        return fnDecimalToRight(value)
    }
    return value
}