import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ExactPayment = ({ sx }) => {
    return (
        <SvgIcon sx={sx} viewBox="0 0 8.5 8.5">
            <path 
                className="st0" 
                d="M4.2,1.1c-1.7,0-3.1,1.4-3.1,3.1c0,1.7,1.4,3.1,3.1,3.1c1.7,0,3.1-1.4,3.1-3.1C7.4,2.5,6,1.1,4.2,1.1z M4.9,6
                C4.8,6,4.7,6.1,4.6,6.1c-0.2,0-0.2,0.1-0.2,0.2c0,0,0,0.1,0,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.2,0-0.3
                c-0.2,0-0.4,0-0.5-0.1C2.8,6,2.9,5.9,2.9,5.4c0-0.2,0.1-0.2,0.3-0.2c0.4,0.2,0.7,0.3,1.1,0.2c0.2,0,0.3-0.1,0.4-0.3
                c0-0.2-0.1-0.3-0.2-0.4C4.2,4.6,4,4.4,3.7,4.3C3.4,4.1,3.1,3.9,3,3.6C2.8,2.9,3.2,2.3,3.9,2.1c0.2,0,0.3-0.1,0.3-0.3
                c0.1,0,0.3,0,0.4,0c0,0.1,0,0.2,0,0.2c0.2,0,0.4,0,0.6,0.1c0.3,0.1,0.4,0.1,0.4,0.5c0,0.3,0,0.4-0.4,0.3C4.8,2.8,4.5,2.7,4.2,2.8
                c-0.2,0-0.3,0.1-0.3,0.3c0,0.2,0.1,0.3,0.2,0.4c0.2,0.1,0.3,0.2,0.5,0.3C4.8,3.9,5,4,5.2,4.1C5.8,4.5,5.8,5.7,4.9,6z M4.2,0
                C1.9,0,0,1.9,0,4.2c0,2.3,1.9,4.2,4.2,4.2c2.3,0,4.2-1.9,4.2-4.2C8.5,1.9,6.6,0,4.2,0z M4.2,7.9c-2,0-3.6-1.6-3.6-3.6
                c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6C7.9,6.2,6.2,7.9,4.2,7.9z"/>
        </SvgIcon>
    )
}

export default ExactPayment