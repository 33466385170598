import React, { useState, useEffect, useContext } from 'react';
import TaDialog from 'components/dialog/TaDialog'
import { Grid, Paper } from '@mui/material'
import TaTypography from 'components/typography/TaTypography'
import { fnFormatNumb } from 'helper/FormatNumb'
import TaFab from 'components/fab/TaFab'
import currency from "currency.js";
import OrderListDetail from "./OrderListDetail"
import TaList from 'components/list/TaList'
import { postOrder } from "../CatalogApi";
import { SnackbarContext } from 'contexts/Snackbar/SnackbarContext'

const bodyCatalogStyles = ({
    margin: '0px 0px 7px 0px',
    backgroundColor: 'var(--main-bg-color0)',
    maxHeight: '400px',
    overflow: 'auto',
})

const ResumeCatalog = ({ orderList, onClose, onDeleteItem, dataCustomer}) => {
    const { sbDispatch } = useContext(SnackbarContext)

    const [isLoading, setIsLoading] = useState(false);
    const [resume, setResume] = useState({
        totalAmount: 0,
        totalQty: 0,
    })
    const fnOnAcceptOrder = async () => {
        setIsLoading(true)
        try {
            const response = await postOrder(dataCustomer.customer, dataCustomer.token, dataCustomer.phone, resume.totalAmount, orderList)
            sbDispatch.api(response.status, response.data);
            if(response.status === 200 && response.data.errorCode === 0){
                onClose(true);
            }
        } catch (error) {

        }
        setIsLoading(false)
    }

    useEffect(() => {
        setResume({
            totalQty: orderList.reduce((val, x) => val + x.qty, 0),
            totalAmount: orderList.reduce((val, x) => currency(val, { precision: 4 }).add(x.amount).value, 0)
        })
    }, [orderList])

    return (
        <TaDialog
            title={'Resumen'}
            onClose={() => onClose()}
            maxWidth={'xs'}
            isOpen={true}
            content={
                <Grid container spacing={1} alignContent='center' alignItems='center'>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Paper sx={bodyCatalogStyles} square={true} elevation={0}>
                            <TaList elevation={0} infinityScroll={false}>
                                {orderList.map(x => <OrderListDetail key={x.uniqueKey} item={x} onDeleteItem={onDeleteItem} />)}
                            </TaList>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align='right'>
                        <Grid container spacing={1} alignContent='center' alignItems='center'>
                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                <TaTypography text={'Total items'} fontSize={'12pt'} align='left' />
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                <TaTypography text={fnFormatNumb(resume.totalQty)} fontSize={'12pt'} align='right' />
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                <TaTypography text={'Total orden'} fontSize={'12pt'} align='left' />
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                                <TaTypography text={fnFormatNumb(resume.totalAmount)} fontSize={'12pt'} align='right' />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align='right'>
                        <TaFab id={'btnAcceptOrder'} icon={'done'} color='green' onClick={fnOnAcceptOrder} disabled={isLoading} />
                    </Grid>
                </Grid>
            }
            loading={isLoading}
        />
    );
};

export default ResumeCatalog;