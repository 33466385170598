import { useEffect, useState } from 'react'

const arrangeParamettersModel = {
  currentPage: 1,
  rowsPerPage: 10,
  rowsPerPageOptions: [10],
  count: 0,
  pageQty: 0
}

const usePagination = (data = [], rows = 1) => {

  const [paginatedData, setPaginatedData] = useState([])

  const [{ currentPage, rowsPerPage, rowsPerPageOptions, count, pageQty }, setArrangeParametters] = useState(arrangeParamettersModel)

  const fnOnChangePage = (page) => setArrangeParametters(preVal => ({ ...preVal, currentPage: page }))

  useEffect(() => {
    if (data.length > 0 && rows > 0) {
      setArrangeParametters(preVal => ({
        ...preVal,
        count: data.length,
        rowsPerPage: rows,
        pageQty: Math.ceil(data.length / rows),
      }))
    }
  }, [data, rows])

  useEffect(() => {
    const fnArrangeData = (data = []) => new Promise((resolve, reject) => {
      try {
        data = data.length > 0
          ? data.slice((currentPage - 1) * rowsPerPage, ((currentPage - 1) * rowsPerPage + rowsPerPage))
          : []
        resolve(data)
      } catch (error) {
        reject(error)
      }
    })
    const fnSetData = async () => {
      const response = await fnArrangeData(data)
      setPaginatedData(response)
    }
    fnSetData()
  }, [currentPage, rowsPerPage, rowsPerPageOptions, count, data, pageQty])

  return { paginatedData, currentPage, rowsPerPage, rowsPerPageOptions, count, fnOnChangePage, pageQty, data }

}

export default usePagination