import { Grid } from '@mui/material';
import TaImg from 'components/img/TaImg';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Logo from 'proyectImages/SimplitPOS01.png'
import Catalog from '../../proyectComponents/Catalog/Catalog';

const CatalogView = () => {
    const [showCatalog, setShowCatalog] = useState(false);
    const history = useHistory()
    const { customer, phone } = history.location.state
    useEffect(() => {
        setShowCatalog((customer))
    }, [])

    return (
        !showCatalog
            ? <Grid container direction="row" justifyContent="center" alignItems="center" sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
            }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"  >
                    <TaImg inStyle={{ width: '90%' }} url={'https://totalaplicaciones.com/appsimages/SimplitPOSPlus/grayorangelogotipo.png'} />
                </Grid>
            </Grid>
            : <Catalog customer={customer} phone={phone} />
    );
};

export default CatalogView;