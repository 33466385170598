import { useContext, useCallback } from "react"
import { CurrencyContext } from "../CurrencyContext"
import { fnFormatNumb } from "helper/FormatNumb"
import { fnMathCalculation } from "helper/MathCalculation"


const useCurrencyFactor = () => {
    const { currencyState: { selectedCurrency, currencyData } } = useContext(CurrencyContext)
    const fnCurrencyFactor = useCallback((value, customCurrency, decimals = 4, inverseCalculation = false, useBase = false) => {
        // console.log('value => ', value)
        let currency, resp
        if ((selectedCurrency || customCurrency) && value && !useBase) {
            if (value > 0) {
                currency = customCurrency ?? currencyData.find(x => x.id === selectedCurrency.id)
                resp = fnMathCalculation(value, currency?.isBase ? 1 : currency.factor, !inverseCalculation ? currency.typeCalc : currency.typeCalc === "*" ? "/" : "*", decimals)
                return resp
            }
        } else if (useBase) {
            currency = currencyData.find(x => x.isBase)
            resp = fnMathCalculation(value, 1, !inverseCalculation ? currency.typeCalc : currency.typeCalc === "*" ? "/" : "*", decimals)
            return resp
        }

        // console.log('value2 => ', value)

        let newValue = value ?? 0

        // console.log('newValue => ', newValue, decimals)
        return {
            amount: newValue,
            amountLabel: fnFormatNumb(newValue),
            amountNoFixed: newValue.toFixedNoRounding(decimals),
            amountNoFixedLabel: fnFormatNumb(+(newValue.toFixedNoRounding(decimals)))
        }
    },[selectedCurrency, currencyData])
    return {
        fnCurrencyFactor,
        currencyData,
    }
}

export default useCurrencyFactor