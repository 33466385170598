import { Grid, ListItemButton, Paper } from '@mui/material'
import TaTypography from 'components/typography/TaTypography';
import { fnCalculateHeights } from 'helper/fnCalculateHeights';
import useWindowSize from 'helper/UseWindowsSize';
import React, { memo, useEffect, useState } from 'react'
import { iconType } from './../fab/Icons'

const GridHomeDetail = memo(({
  classes: {
    layoutSelected, layout
  },
  itemData, onFocus, onClick, selectedKey,
  onKeyDown, onHoverGridItem, onLeave
  ,
}) => {

  const { id, name, nexthPath, visible, expand, isExpanded, subMenu } = itemData
  const [icon, setIcon] = useState(null)
  const { height } = useWindowSize()

  useEffect(() => {
    let icon = ''
    switch (id) {
      case '0':
        icon = 'sales'
        break;
      case '1':
        icon = 'setting'
        break;
      case '1-0':
      case '19-0':
      case '71-0':
        icon = 'backArrow'
        break;
      case '1-1':
        icon = 'setting'
        break;
      case '1-2':
        icon = 'fiscalPrinter'
        break;
      case '1-3':
        icon = 'restaurant'
        break;
      case '1-4':
        icon = 'telegram'
        break;
      case '1-5':
        icon = 'printer'
        break;
      case '1-6':
        icon = 'balance'
        break;
      case '1-7':
        icon = 'catalog'
        break
      case '1-8':
        icon = 'whatsApp'
        break;
      case '2':
        icon = 'listAlt'
        break;
      case '19':
        icon = 'masters'
        break;
      case '19-1':
        icon = 'category'
        break;
      case '19-2':
        icon = 'servProd'
        break;
      case '19-3':
        icon = 'clients'
        break;
      case '19-4':
        icon = 'provider'
        break;
      case '19-5':
        icon = 'storage'
        break;
      case '19-6':
        icon = 'seller'
        break;
      case '19-7':
        icon = 'payment'
        break;
      case '19-8':
        icon = 'operCode'
        break;
      case '19-9':
        icon = 'server'
        break;
      case '19-10':
        icon = 'percent'
        break;
      case '19-11':
        icon = 'retention'
        break;
      case '20':
        icon = 'cashClosure'
        break;
      case '21':
        icon = 'restaurant'
        break;
      case '22':
        icon = 'receivable'
        break;
      case '70':
        icon = 'cashClose'
        break;
      case '71':
        icon = 'offline'
        break;
      case '71-1':
        icon = 'masters'
        break;
      case '71-2':
        icon = 'statusTran'
        break;
      case '71-3':
        icon = 'formats'
        break;
      case '71-4':
        icon = 'contingencyPrin'
        break;
      case '71-5':
        icon = 'contingencySuc'
        break;
      case '72':
        icon = 'printer'
        break;
      case '74':
        icon = 'documentInfo'
        break;
      case '75':
        icon = 'dispatch'
        break;
      case '76':
        icon = 'merchants'
        break;
      case '77':
        icon = 'clearSheets'
        break;
      case '78':
        icon = 'exchange'
        break;
      case '79':
        icon = 'info'
        break;
      case '80':
        icon = 'dashboard'
        break;
      case '100':
        icon = 'barcodeScan'
        break;
      default:
        break;
    }
    setIcon(iconType(icon, false, true))
  }, [])

  return (<>
    <ListItemButton
      id={`GridItemHome`}
      sx={{ padding: '0px' }}
      onFocus={() => onFocus(itemData)}
      onClick={() => onClick(itemData)}
      onMouseEnter={() => onHoverGridItem(itemData)}
      onKeyDown={(e) => onKeyDown(e, itemData)}
      onMouseLeave={onLeave}>
      <Paper id={`paper ${id}`} elevation={0} square={true} classes={{ root: (selectedKey === id) ? layoutSelected : layout }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper id={`paperGridItemContent: ${id}`} elevation={0} square={true} sx={{
              backgroundColor: 'var(--main-bg-color13)',
              height: '80px',
              color: 'var(--main-bg-color0)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              padding: '0px 5px 0px 5px'
            }}>
              <Grid container columnSpacing={1} alignItems={'center'} >
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2} align={'center'}>
                  {icon}
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10} xl={10} align={'center'}>
                  <TaTypography color={'var(--main-text-color1)'} fontSize={'12pt'} text={`${name}`} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid >
      </Paper >
    </ListItemButton >
  </>
  )
})

export default GridHomeDetail