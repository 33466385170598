import { ordinaryLabelStyles, ordinaryStyles } from './ThemeTaTexfield/Ordinary'
import { whiteLabelStyles, whiteStyles } from './ThemeTaTexfield/White'

export const fnDefineTheme = (theme, noUnderLine, textAlign, fontSize, noActiveUnderLine) => {
    let result
    switch (theme) {
        case 'ordinary':
            result = ordinaryStyles({ noUnderLine, textAlign })
            break;
        case 'white':
            result = whiteStyles({ noUnderLine, textAlign, noActiveUnderLine })
            break;

        default:
            break;
    }
    return result
}

export const fnDefineThemeLabel = (theme) => {
    let result
    switch (theme) {
        case 'ordinary':
            result = ordinaryLabelStyles()
            break;
        case 'white':
            result = whiteLabelStyles()
            break;

        default:
            break;
    }
    return result
}