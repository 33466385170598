import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const PercentIcon = ({ sx }) => {
    return (
        <SvgIcon sx={sx} >
            <path 
                className="st0" 
                d="M18.5,3.5L3.5,18.5L5.5,20.5L20.5,5.5M7,4A3,3 0 0,0 4,7A3,3 0 0,0 7,10A3,3 0 0,0 10,7A3,3 0 0,0 7,4M17,14A3,3 0 0,0 14,17A3,
                3 0 0,0 17,20A3,3 0 0,0 20,17A3,3 0 0,0 17,14Z"/>
        </SvgIcon>
    )
}

export default PercentIcon