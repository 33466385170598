import Print from '@mui/icons-material/Print'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Toc from '@mui/icons-material/Toc'
import Key from '@mui/icons-material/VpnKey'
import RestoreIcon from '@mui/icons-material/Restore'
import CollectionsIcon from '@mui/icons-material/Collections'
import GridOnIcon from '@mui/icons-material/GridOn'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import Sync from '@mui/icons-material/Sync'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import NotInterestedIcon from '@mui/icons-material/NotInterested'
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import AddLocation from '@mui/icons-material/AddLocation'
import GridOn from '@mui/icons-material/GridOn'
import Receipt from '@mui/icons-material/Receipt'
import MergeType from '@mui/icons-material/MergeType'
import CallMade from '@mui/icons-material/CallMade'
import OpenInNew from '@mui/icons-material/OpenInNew'
import LockOpen from '@mui/icons-material/LockOpen'
import NotInterested from '@mui/icons-material/NotInterested'
import Message from '@mui/icons-material/Message'
import Telegram from "@mui/icons-material/Telegram"
import MoneyIcon from '@mui/icons-material/Money'
import CommentIcon from '@mui/icons-material/Comment'
import File from '@mui/icons-material/Description'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Dehaze';
import RemoveIcon from '@mui/icons-material/Remove';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/SettingsSharp';
import Storage from '@mui/icons-material/Storage';
import ArrowIcon from '@mui/icons-material/KeyboardArrowRight';
import Totalize from '@mui/icons-material/Done';
import Home from '@mui/icons-material/Home';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CashIcon from '@mui/icons-material/MonetizationOn';
import CardIcon from '@mui/icons-material/CreditCard';
import List from '@mui/icons-material/ListAlt';
import Star from '@mui/icons-material/Star';
import FileCopy from '@mui/icons-material/FileCopy';
import SystemUpdate from '@mui/icons-material/SystemUpdate';
import CloudDownload from '@mui/icons-material/CloudDownload';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import { ClearAll } from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import InventoryIcon from '@mui/icons-material/Inventory';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import GradingIcon from '@mui/icons-material/Grading';
import PeopleIcon from '@mui/icons-material/People';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import CalculateIcon from '@mui/icons-material/Calculate';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import BalanceIcon from '@mui/icons-material/Balance';
import PaymentIcon from '@mui/icons-material/Payment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HailIcon from '@mui/icons-material/Hail';
import RuleIcon from '@mui/icons-material/Rule';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import PaidIcon from '@mui/icons-material/Paid';
import ArticleIcon from '@mui/icons-material/Article';
import QuizIcon from '@mui/icons-material/Quiz';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import BuildIcon from '@mui/icons-material/Build';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ReplayIcon from '@mui/icons-material/Replay';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import RefreshIcon from '@mui/icons-material/Refresh';
//COMPONENTS
import EraseIcon from './icons/EraseIcon'
import EditImageIcon from './icons/EditImageIcon'
import ImageIcon from './icons/ImagesIcon'
import PercentIcon from './icons/PercentIcon'
import PriceVisorIcon from './icons/PriceVisorIcon'
import ExactPayment from './icons/ExactPayment'
import BarcodeScan from './icons/BarcodeScan'
import FE from './icons/FE'
import OneTileFilter from './icons/OneTileFilter'
import FourTileFilter from './icons/FourTileFilter'
import MultiTileFilter from './icons/MultiTileFilter'
import AccountCash from './icons/AccountCash'
import PrinterPos from './icons/PrinterPos'
import DownloadDoc from './icons/DownloadDocument'
import ComputerIcon from '@mui/icons-material/Computer'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Cube from './icons/Cube'

export const iconType = (type, miniFab = false, maxIcon = false) => {
  switch (type) {
    case "add":
      return <AddIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "backArrow":
      return <ArrowBackIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "forwardArrow":
      return <ArrowForwardIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "clear":
    case "exit":
      return <ClearIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "discard":
    case "delete":
      return <DeleteIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "done":
      return <DoneIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "edit":
      return <EditIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "erase":
      return <EraseIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "info":
      return <InfoIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "menu":
      return <MenuIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "percent":
      return <PercentIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "pricevisor":
      return <PriceVisorIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "remove":
      return <RemoveIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "save":
      return <SaveIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "search":
      return <SearchIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "setting":
      return <SettingsIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "send":
      return <SendIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "storage":
      return <Storage sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "arrow":
    case "totalarrow":
      return <ArrowIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "total":
    case "check":
      return <Totalize sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "home":
      return <Home sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "upload":
      return <CloudUploadIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "download":
      return <CloudDownloadIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "cash":
      return <CashIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "card":
      return <CardIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "list":
      return <List sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "favorite":
      return <Star sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "favoriteB":
    case "editFavsSelected":
      return <StarBorderOutlined sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "image":
      return <ImageIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "editImage":
      return <EditImageIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "editImageSelected":
      return <EditImageIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "systemUpdate":
      return <SystemUpdate sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "cloudDownload":
      return <CloudDownload sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "exactPayment":
      return <ExactPayment sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "FileCopy":
      return <FileCopy sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "camera":
      return <PhotoCamera sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'user':
      return <AccountCircle sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'disableUser':
      return <AccountCircle sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'printer':
      return <Print sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'barcodeScan':
      return <BarcodeScan sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case '...':
      return <MoreVertIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'visibility':
      return <Visibility sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'visibilityOff':
      return <VisibilityOff sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'oneTileFilter':
      return <OneTileFilter sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'fourTileFilter':
      return <FourTileFilter sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'multiTileFilter':
      return <MultiTileFilter sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'multiSelect':
      return <Toc sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'key':
      return <Key sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'FE':
      return <FE sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'accountCash':
      return <AccountCash sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'printerPos':
      return <PrinterPos sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'restore':
      return <RestoreIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'mapType':
      return <CollectionsIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'listMode':
      return <GridOnIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'mergeTables':
      return <LibraryAddIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'clearTable':
      return <DeleteSweepIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'sync':
      return <Sync sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'disable':
      return <NotInterestedIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'downloadDoc':
      return <DownloadDoc sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'addLocation':
      return <AddLocation sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'polygon':
      return <GridOn sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "merge":
      return <MergeType sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "document":
      return <Receipt sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "moveDoc":
      return <CallMade sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "openTable":
      return <OpenInNew sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "freeTable":
    case "lockOpen":
      return <LockOpen sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "cancel":
      return <NotInterested sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "message":
      return <Message sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case "telegram":
      return <Telegram sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'money':
      return <MoneyIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'comment':
      return <CommentIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'file':
      return <File sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'backupRestore':
      return <SettingsBackupRestoreIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'arrowUp':
      return <ArrowUpwardIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'arrowDown':
      return <ArrowDownwardIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'clearAll':
      return <ClearAll sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'computer':
      return <ComputerIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'documentInfo':
      return <ReceiptIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'uploadFile':
      return <UploadFileIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'sales':
      return <PointOfSaleIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'listAlt':
      return <ListAltIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'offline':
      return <WifiOffIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'cashClose':
      return <ReceiptLongIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'exchange':
      return <CurrencyExchangeIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'dispatch':
      return <GradingIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'merchants':
      return <PeopleIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'restaurant':
      return <RestaurantIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'clearSheets':
      return <FolderDeleteIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'cashClosure':
      return <CalculateIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'masters':
      return <RecentActorsIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'balance':
      return <BalanceIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'payment':
      return <PaymentIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'server':
      return <AssignmentIndIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'category':
      return <FactCheckOutlinedIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'servProd':
      return <ShoppingCartOutlinedIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'clients':
      return <PeopleAltIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'provider':
      return <LocalShippingIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'seller':
      return <HailIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'operCode':
      return <RuleIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'retention':
      return <CreditCardOffIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'statusTran':
      return <PaidIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'formats':
      return <ArticleIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'contingencyPrin':
      return <QuizIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'contingencySuc':
      return <HelpCenterOutlinedIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'receivable':
      return <RequestQuoteOutlinedIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'fiscalPrinter':
      return <BuildIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'whatsApp':
      return <WhatsAppIcon sx={miniFab ? { width: '25px', height: '25px' } : maxIcon ? { width: '35px', height: '35px' } : {}} />
  // return <WhatsAppIcon sx={{ fontSize: '20pt' }} color={'var(--main-bg-color0)'} />
    case 'catalog':
      return <MenuBookIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'delivery':
      return <DeliveryDiningIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'dashboard':
      return <InsertChartIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'cube':
      return <Cube sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    case 'refresh':
      return <RefreshIcon sx={miniFab ? { width: '20px', height: '20px' } : maxIcon ? { width: '30px', height: '30px' } : {}} />
    default:
      return null
  }
}