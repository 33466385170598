import { Grid } from '@mui/material';
import React, { useState } from 'react';
import TaImg from 'components/img/TaImg'
import TaTypography from 'components/typography/TaTypography'
import Logo from 'proyectImages/SimplitPOS01.png';
import TaFab from 'components/fab/TaFab';
import ResumeCatalog from './ResumeCatalog';
import TaBadge from 'components/badge/TaBadge'
import useWindowSize from 'helper/UseWindowsSize';

const HeaderCatalog = ({ customerName, orderList, onDeleteItem, onClearOrder, dataCustomer }) => {

    const [isOpenShop, setIsOpenShop] = useState(false);
    const { isMobile } = useWindowSize()

    const fnOnCloseShop = (clearOrder) => {
        setIsOpenShop(false)
        if (clearOrder) {
            onClearOrder();
        }
    };
    const fnOnOpenShop = () => setIsOpenShop(true);

    return (
        <Grid container spacing={2} alignContent='center' alignItems={'center'} sx={{ padding: '10px' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
                <TaImg inStyle={{ width: isMobile ? '50%' : '317px' }} url={'https://totalaplicaciones.com/appsimages/SimplitPOSPlus/grayorangelogotipo.png'} />
            </Grid>
            <Grid item xs={9} sm={10} md={10} lg={10} xl={10}>
                <TaTypography text={customerName} fontSize={'16pt'} align='left' />
            </Grid>
            <Grid item xs={3} sm={2} md={2} lg={2} xl={2} align="center">
                <TaBadge invisible={orderList.length === 0} extContent={orderList.length}
                    badgeColor={'var(--main-bg-color3)'} fontColor={'var(--main-bg-color0)'} top={'30%'} badgeWidth={orderList.length > 2 ? '28px' : '20px'}>
                    <TaFab id={'btnPurchase'} icon={'servProd'} color='green' onClick={fnOnOpenShop} />
                </TaBadge>
            </Grid>
            {isOpenShop && <ResumeCatalog orderList={orderList} onClose={fnOnCloseShop} onDeleteItem={onDeleteItem} dataCustomer={dataCustomer} />}
        </Grid>
    );
};

export default HeaderCatalog;