import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const FE = ({ sx }) => {
    return (
        <SvgIcon sx={sx} viewBox="0 0 6.8 4.7">
            <path 
                className="st0" 
                d="M2.5,1.4c-0.1,0-0.3,0-0.4,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C1.8,1.7,1.6,2,1.6,2.4
                c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0.1c0,0,0,0,0,0
                c0,0,0,0,0,0C0.7,2.7,0.6,2.9,0.6,3.1c0,0.3,0.2,0.5,0.5,0.6c0,0,0,0,0,0h0c0.2,0,0.3,0.2,0.3,0.3c0,0.2-0.1,0.3-0.3,0.3
                c0,0,0,0,0,0H1.1c-0.3,0-0.6-0.2-0.8-0.4l0,0C0.1,3.6,0,3.4,0,3.1c0-0.4,0.2-0.8,0.5-1c0,0,0,0,0.1,0C0.7,2,0.9,1.9,1.1,1.9
                C1.2,1.5,1.5,1.2,1.8,1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C2,0.9,2.2,0.8,2.4,0.8c0.1,0,0.2,0,0.2,0C2.9,0.4,3.5,0,4.1,0
                c0,0,0.1,0,0.1,0L4.1,0.6C3.6,0.6,3.2,1,3,1.5c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0C2.8,1.5,2.7,1.4,2.5,1.4z M6.3,1.8
                C6.3,1.8,6.2,1.7,6.3,1.8C6.1,1.7,6,1.6,5.9,1.6L5.7,2.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0.1c0,0,0,0,0,0C6,2.4,6.1,2.6,6.1,2.8
                c0,0.4-0.3,0.8-0.7,0.8h0c0,0,0,0-0.1,0V3.3L4.6,3.9l0.7,0.7l0-0.3c0,0,0,0,0.1,0l0,0c0.8-0.1,1.1-0.5,1.1-0.5
                c0.2-0.2,0.4-0.6,0.4-0.9C6.8,2.4,6.6,2,6.3,1.8z M3.1,3.5L2.7,3.4L2.6,3.7L3,3.8L3.1,3.5z M3.4,4l0.4,0.1l0.1-0.4L3.5,3.6L3.4,4z
                 M2.9,4.3l0.3,0.1L3.3,4L3,4L2.9,4.3z M2.4,4.6l0.2,0.1l0.1-0.2L2.4,4.4L2.4,4.6z M4.8,3.4C4.6,3.5,4.4,3.6,4.2,3.6
                c-0.1,0-0.1,0-0.2,0l0.1-0.4L3.7,3.1L3.6,3.4L3.2,3.3L3.3,3L2.9,2.8L2.8,3.2l-0.1,0L2.4,3.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
                c0,0,0,0,0,0c0,0,0,0,0,0c1-0.6,1.7-1.7,1.9-2.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0L6,0.5h0l0,0
                c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C5.9,1.7,5.5,2.6,4.8,3.4C4.8,3.4,4.8,3.4,4.8,3.4z M4.3,0.9
                l1.4,0.4c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1L4.3,0.9C4.3,0.8,4.3,0.8,4.3,0.9C4.3,0.8,4.3,0.9,4.3,0.9z M4.1,1.5l1.4,0.4
                c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1L4.1,1.5C4.1,1.4,4.1,1.4,4.1,1.5C4.1,1.4,4.1,1.5,4.1,1.5z M5.1,2.5C5.2,2.5,5.2,2.5,5.1,2.5
                C5.2,2.4,5.2,2.4,5.1,2.5L3.8,2c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1L5.1,2.5z"/>
        </SvgIcon>
    )
}

export default FE