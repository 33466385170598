import { createContext, useState } from 'react'

const SNACKBAR_INIT = { isOpen: false, variant: '', message: '' }
const apiVariant = (statusCode) => (statusCode === 200 || statusCode === 201) ? 'success' : statusCode === '401' ? 'warning' : 'error'
const apiVariantV2 = (statusCode, errorCode) => (statusCode === 200 || statusCode === 201) && (errorCode === 0) ? 'success' : 'error'

const SnackbarContext = createContext(SNACKBAR_INIT)
const SnackbarProvider = ({ children }) => {
    const [sbState, setSbState] = useState(SNACKBAR_INIT)
    const success = (message) => setSbState({ isOpen: true, variant: 'success', message })
    const warning = (message) => setSbState({ isOpen: true, variant: 'warning', message })
    const error = (message) => setSbState({ isOpen: true, variant: 'error', message })
    const api = (statusCode, data) => setSbState({
        isOpen: (data.message) ? (data.message !== "") : false,
        variant: apiVariant(statusCode),
        message: (data.message !== "") ? data.message : ""
    })
    const apiV2 = (statusCode, data) => setSbState({
        isOpen: (data.message) ? (data.message !== "") : false,
        variant: apiVariantV2(statusCode, data.errorCode),
        message: (data.message !== "") ? data.message : ""
    })
    const close = () => setSbState(SNACKBAR_INIT)

    const sbDispatch = {
        success: success,
        warning: warning,
        error: error,
        apiV2: apiV2,
        api: api,
        close: close,
    }
    return (
        <SnackbarContext.Provider value={{ sbState, sbDispatch }}>
            {children}
        </SnackbarContext.Provider>

    )
}

export { SnackbarContext, SnackbarProvider }