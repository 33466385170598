import { Grid, ListItemButton, Paper } from '@mui/material'
import TaImg from 'components/img/TaImg'
import TaTypography from 'components/typography/TaTypography'
import { fnCalculateHeights } from 'helper/fnCalculateHeights'
import { memo } from 'react'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

const paymentMethodGridDetailStyle = (gridHeight, name, useImages) => ({
  backgroundColor: 'var(--main-bg-color11)',
  height: !useImages && gridHeight,
  padding: '5px 0px 5px 0px',
  display: 'flex',
  alignItems: 'center',
  width: '100%'
})

const GridPaymentMethodDetail = memo(({
  classes: {
    layout, content, footer,
    layoutSelected
  },
  onClick, itemData, onKeyDown,
  useImages = false, onHoverGridItem, selectedKey,
  gridHeight, onLeave, disabled
}) => {

  const { name, uniqueKey, urlImage, urlImage2, isHidden, visibilityType } = itemData
  const gridTitle = visibilityType === 1 ? 9 : 12
  const style = paymentMethodGridDetailStyle(gridHeight, name, useImages)
  return (
    <ListItemButton
      id={`GridItem:${uniqueKey}`}
      sx={{ padding: '0px' }}
      onClick={() => onClick(itemData)}
      onKeyDown={(e) => onKeyDown(e, itemData)}
      onMouseEnter={() => onHoverGridItem(itemData)}
      onMouseLeave={onLeave}
      disabled={disabled}
      disableRipple={true}
      disableTouchRipple={true} >
      <Paper elevation={0} square={true} classes={{ root: (selectedKey === uniqueKey) ? layoutSelected : layout }}>
        <Grid container alignItems={'center'} >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper id={`paperGridPaymentMethodContent: ${uniqueKey}`} elevation={0} square={true} sx={{
              overflow: useImages ? undefined : 'hidden',
              padding: useImages ? '0px' : '0px 5px 0px 5px',
              height: useImages ? fnCalculateHeights([gridHeight, (name.length >= 20) ? 59 : (name.length >= 15) ? 42 : 25]) : '',
              backgroundColor: 'transparent',
            }} >
              {useImages && <TaImg id={`gridItemImage: ${uniqueKey}`} url={urlImage} defaultUrl={urlImage2} inStyle={{
                paddingTop: '0px',
                marginLeft: 'auto',
                marginRight: 'auto',
                height: fnCalculateHeights([gridHeight]),
                width: '100%',
              }} />}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper id={`paperGridCategoryFooter: ${uniqueKey}`} elevation={0} square={true} sx={style}>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3} align={'center'} hidden={visibilityType ? visibilityType === 0 : true}>
                {
                  (isHidden)
                    ? <VisibilityOffIcon sx={{ padding: '3px 0px 0px 0px', fontSize: '15pt', color: 'var(--main-bg-color0)' }} />
                    : <VisibilityIcon sx={{ padding: '3px 0px 0px 0px', fontSize: '15pt', color: 'var(--main-bg-color0)' }} />
                }
              </Grid>
              <Grid item xs={gridTitle} sm={gridTitle} md={gridTitle} lg={gridTitle} xl={gridTitle} align={'center'}>
                <TaTypography text={name.toUpperCase()} fontFamily={'ArchivoBold'} fontSize={'12pt'} width={'100%'} align={'center'} color={'var(--main-text-color1)'} />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </ListItemButton>
  )
})

export default GridPaymentMethodDetail