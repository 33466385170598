export const fnCompareStrings = (value1, value2) => String(value1).toLowerCase() === String(value2).toLowerCase()
export const fnIncludeStrings = (value1, value2) => {
    if(typeof(value1) === 'string'){
        return String(value1).toLowerCase().includes(String(value2).toLowerCase())
    }else if(typeof(value1) === 'object'){
        let newValue;
        for(let prop in value1){
            newValue += value1[prop]
        }
        return String(newValue).toLowerCase().includes(String(value2).toLowerCase())
    }
}