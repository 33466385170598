import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Paper,
    Grid,
} from "@mui/material/"
import Loader from '../loader/Loader'
import TaTypography from '../typography/TaTypography'
import { makeStyles } from '@mui/styles'
import TaFab from 'components/fab/TaFab'

const dialogStyles = makeStyles({
    title: {
        margin: "5px",
        padding: "0px !important",
        backgroundColor: 'var(--main-bg-color1)',
        textAlign: 'center'
    },
    content: {
        margin: props => (props.noStyles) || (props.fullScreen) ? '0px' : "5px",
        padding: props => (props.noStyles) || (props.fullScreen) ? '0px' : "10px !important",
        overflowX: "hidden !important",
    },
    actions: {
        margin: props => (props.noAction) ? '0px' : "5px",
        padding: props => (props.noAction) ? '0px' : "10px !important",
    },
})

const TaDialog = memo(({
    isOpen, onClose, fullWidth,
    maxWidth, title, content,
    actions, loading = false, elevation,
    showLoader = true, fullScreen, noStyles,
    onEntered, noAction = false }) => {

    const classes = dialogStyles({ fullScreen, noStyles, noAction })

    const fnOnEntered = (element, isAppearing) => isAppearing && onEntered && onEntered()

    const fnOnClose = () => {
        return loading ? '' : onClose()
    }

    return (
        <Dialog
            open={isOpen}
            onClose={fnOnClose}
            fullWidth={fullWidth && fullWidth}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            TransitionProps={{
                onEntered: fnOnEntered
            }}
            PaperProps={
                {
                    elevation: elevation,
                    style: {
                        backgroundColor: 'var(--main-bg-color0)', overflowX: 'hidden'
                    }
                }
            }>
            {title && <DialogTitle className={classes.title}>{
                <Grid container alignItems={'center'} justifyContent={'center'} >
                    <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5} xl={1.5}></Grid>
                    <Grid item xs={8.5} sm={9} md={9} lg={9} xl={9}>
                        <TaTypography color={'var(--main-text-color1)'} isTitle text={title} />
                    </Grid>
                    <Grid item xs={2} sm={1.5} md={1.5} lg={1.5} xl={1.5} align={'right'}>
                        <TaFab id={`btnCloseModal`} icon={'clear'} color={'transparent'} onClick={onClose} disabled={loading} />
                    </Grid>
                </Grid>
            }</DialogTitle>}
            {content && <DialogContent className={classes.content}>{content}</DialogContent>}
            {actions && <DialogActions className={classes.actions}>{actions}</DialogActions>}
            {showLoader && <Paper elevation={0} style={{ height: "10px" }}><Loader isLoading={loading} /></Paper>}
        </Dialog>
    )
})

TaDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    fullWidth: PropTypes.bool,
    fullScreen: PropTypes.bool,
    maxWidth: PropTypes.string,
    title: PropTypes.any,
    content: PropTypes.any,
    actions: PropTypes.any,
    elevation: PropTypes.number,
    isTransparent: PropTypes.bool,
    showLoader: PropTypes.bool,
    noStyles: PropTypes.bool,
}

TaDialog.defaultProps = {
    isOpen: false,
    fullWidth: true,
    maxWidth: "sm",
    elevation: 2,
    isTransparent: false,
    noStyles: false
}

export default TaDialog