import { Grid } from '@mui/material'
import TaGridList from 'components/gridlist/TaGridList'
import React from 'react'
import { memo } from 'react'
import { useForm } from 'react-hook-form'
import TaTextField from 'components/textfield/TaTextField'
import { useRef } from 'react'
import { fnIncludeStrings } from "helper/CompareValues"
import { fnCalculateHeights } from 'helper/fnCalculateHeights'
import useWindowSize from 'helper/UseWindowsSize'

const CatalogCategories = memo(({ categoryList = [], onSelectCategory }) => {

  const gridListRef = useRef(null)

  const { register, watch } = useForm({
    defaultValues: {
      search: ''
    }
  })

  const { height, isMobile } = useWindowSize()

  return (
    <Grid id={'categoriesBody'} container sx={{ padding: isMobile ? '0px 10px 0px 10px' : '10px' }} >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: '0px 5px 5px 5px' }} >
        <TaTextField
          id={'txtSearchCategory'}
          register={register('search')}
          label={'Buscar categoria'}
          type={'search'} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TaGridList
          height={fnCalculateHeights([height, isMobile ? 340 : 316])}
          onCallback={onSelectCategory}
          data={[{categoryId: '', name: 'Todos', uniqueKey: '-000001'},...categoryList.filter(x => fnIncludeStrings(x, watch('search')))]}
          useImages={false}
          gridType={'category'}
          ref={gridListRef} />
      </Grid>
    </Grid>
  )
})

export default CatalogCategories