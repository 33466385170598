import React, { useState } from 'react';
import TaDialog from 'components/dialog/TaDialog'
import { Grid } from '@mui/material'
import TaTypography from 'components/typography/TaTypography'
import { fnFormatNumb } from 'helper/FormatNumb'
import TaFab from 'components/fab/TaFab'
import TaNumberTextField from "components/textfield/TaNumberTextField"
import TaImg from 'components/img/TaImg'
import currency from "currency.js";

const ItemCatalog = ({ item, onClose }) => {

    const { itemName, descrip, pricetax, urlImage } = item
    const [qty, setQty] = useState(1);
    const fnOnAddItem = () => setQty(preVal => (preVal + 1))
    const fnOnLessItem = () => setQty(preVal => ((preVal - 1) < 0 ? 1 : (preVal - 1)))
    const fnOnChangeItem = (name, value) => setQty(value < 0 ? 1 : value)

    const fnOnAccept = () => onClose({ ...item, qty: qty, amount: currency(pricetax, { precision: 4 }).multiply(qty).value  })

    return (
        <TaDialog
            title={itemName}
            onClose={() => onClose()}
            maxWidth={'xs'}
            isOpen={true}
            content={
                <Grid container spacing={1} alignContent='center' alignItems='center'>
                    <Grid item xs={12} sm={6} md={5} lg={5} xl={5}>
                        <TaImg url={urlImage} inStyle={{
                            paddingTop: '0px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            height: '150px',
                            width: '100%',
                        }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={7} lg={7} xl={7}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography text={descrip} fontSize={'14pt'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography text={`Precio ${fnFormatNumb(pricetax)}`} fontSize={'14pt'} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container spacing={1} alignContent={'center'}>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                        <TaFab id={'btnLessItem'} icon={'remove'} color='red' onClick={fnOnLessItem} />
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'center'} >
                                        <TaNumberTextField
                                            name={'txtQtyItem'}
                                            onChange={fnOnChangeItem}
                                            value={qty}
                                            textAlign={'center'} />
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} align={'right'}>
                                        <TaFab id={'btnSumItem'} icon={'add'} color='green' onClick={fnOnAddItem} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TaTypography text={`Total ${fnFormatNumb(pricetax * qty)}`} fontSize={'14pt'} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align='right'>
                        <TaFab id={'btnAccept'} icon={'done'} color='green' onClick={fnOnAccept} />
                    </Grid>
                </Grid>
            }
        />
    );
};

export default ItemCatalog;