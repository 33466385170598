export const initialModel = {
    orderList: [],
    categoryList: [],
    itemList: [],
    customerName: '',
    selectedCategory: ''
}

export const postOrderModel = (phone, totalAmount, data) => ({
    clientPhone: phone,
    totalAmount: totalAmount,
    orderDetails: data.map(x => ({
        isServ: x.isServ,
        itemId: x.itemId,
        itemName: x.itemName,
        isUnit: x.isUnit,
        qty: x.qty,
        price: x.price,
        amount: x.amount,
        isExempt: x.isExempt
    }))
})