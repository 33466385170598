import { useEffect, useState } from 'react'
import useWindowSize from 'helper/UseWindowsSize'

const useDinamicHeights = (model) => {

  const { height, isTablet, isMobile, width } = useWindowSize()
  const [heights, setHeights] = useState(model(height, isTablet, isMobile, width))
  useEffect(() => setHeights(model(height, isTablet, isMobile, width)), [height, isTablet, isMobile, width])
  return heights
}

export default useDinamicHeights