import { memo, useState, forwardRef, useImperativeHandle } from 'react'
import { Grid, MobileStepper, Paper, Step, StepLabel, Stepper } from '@mui/material'
import { fnMaxIndex } from 'helper/MaxIndex'
import { fnMinIndex } from 'helper/MinIndex'
import TaFab from 'components/fab/TaFab'
import TaTypography from 'components/typography/TaTypography'
import { makeStyles } from '@mui/styles'

const stepperStyles = makeStyles({
  stepperSt: {
    backgroundColor: props => props.backgroundColor !== undefined ? props.backgroundColor : 'transparent',
    width: '100%',
  }
})

const TaStepper = memo(forwardRef(({
  isMobile, labelArray = [], fabColor, btnDisabled,
  children, btnNextHidden, btnBackHidden, onlyMobile = false,
  hiddenLabel = false, backgroundColor, contentHeight, hideStepper = false,
  topMobileStepper = false
}, ref) => {

  const classes = stepperStyles({ backgroundColor })
  const [activeStep, setActiveStep] = useState(0)
  const fnOnBackStep = () => setActiveStep(fnMinIndex(0, activeStep))
  const fnOnNextStep = () => setActiveStep(fnMaxIndex(labelArray.length - 1, activeStep))
  const isHiddenLabel = (isMobile) || !(labelArray.length < 2) || (!hiddenLabel)
  const isHiddenStepper = onlyMobile || labelArray.length < 2 || isMobile

  useImperativeHandle(ref, () => {
    return {
      nextStep: fnOnNextStep,
      setStep: setActiveStep,
    }
  }, [activeStep])

  return (
    <Grid container rowSpacing={2} alignItems={'center'} justifyContent={'center'} sx={{ padding: '5px 0px 0px 0px' }}>
      {!isHiddenLabel && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
        <TaTypography id={`activeStepLbl${activeStep}`} fontSize={'16pt'} text={labelArray[activeStep].name} align={'center'} color={'var(--main-text-color0)'} />
      </Grid>}
      {!isHiddenStepper && <>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} align={'left'}>
          <TaFab
            id={'btnBackStep'}
            color={fabColor ?? 'orange'}
            icon={'backArrow'}
            onClick={fnOnBackStep}
            hidden={btnBackHidden}
            disabled={activeStep === 0} />
        </Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10} >
          <Stepper activeStep={activeStep} alternativeLabel >
            {labelArray.map((label) => <Step key={label.id}><StepLabel ><TaTypography text={label.name} align={'center'} color={'var(--main-text-color0)'} /></StepLabel></Step>)}
          </Stepper>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} align={'right'}>
          <TaFab
            id={'btnNextStep'}
            color={fabColor ?? 'orange'}
            icon={'forwardArrow'}
            onClick={fnOnNextStep}
            disabled={btnDisabled[activeStep] ?? false}
            hidden={btnNextHidden || activeStep === labelArray.length - 1} />
        </Grid>
      </>}
      {(isMobile && topMobileStepper && labelArray.length > 1 && hideStepper === false) && <MobileStepper
        steps={labelArray.length}
        classes={{ root: classes.stepperSt }}
        position={'static'}
        variant={'dots'}
        activeStep={activeStep}
        nextButton={btnNextHidden ? <div style={{ margin: '25px' }}></div> : <TaFab
          id={'btnNextStep'}
          color={fabColor ?? 'orange'}
          icon={'forwardArrow'}
          onClick={fnOnNextStep}
          hidden={btnNextHidden}
          disabled={activeStep === labelArray.length - 1 || (btnDisabled[activeStep] ?? false)} />}
        backButton={<TaFab
          id={'btnBackStep'}
          color={fabColor ?? 'orange'}
          icon={'backArrow'}
          onClick={fnOnBackStep}
          hidden={btnBackHidden}
          disabled={activeStep === 0} />}
      />}
      {onlyMobile && !isMobile
        ? children
        : <Paper id={'stepperContentPaper'} elevation={0} sx={{ padding: '5px', margin: '15px 0px 0px 0px', height: contentHeight ?? '100%', width: '100%' }}>
          {children.filter(x => typeof x === 'object')[activeStep]}
        </Paper>}
      {(isMobile && !topMobileStepper && labelArray.length > 1 && hideStepper === false) && <MobileStepper
        steps={labelArray.length}
        classes={{ root: classes.stepperSt }}
        position={'static'}
        variant={'dots'}
        activeStep={activeStep}
        nextButton={btnNextHidden ? <div style={{ margin: '25px' }}></div> : <TaFab
          id={'btnNextStep'}
          color={fabColor ?? 'orange'}
          icon={'forwardArrow'}
          onClick={fnOnNextStep}
          hidden={btnNextHidden}
          disabled={activeStep === labelArray.length - 1 || (btnDisabled[activeStep] ?? false)} />}
        backButton={<TaFab
          id={'btnBackStep'}
          color={fabColor ?? 'orange'}
          icon={'backArrow'}
          onClick={fnOnBackStep}
          hidden={btnBackHidden}
          disabled={activeStep === 0} />}
      />}
    </Grid>
  )
}))

TaStepper.defaultProps = {
  isMobile: false,
  btnDisabled: false,
  btnNextHidden: false,
  btnBackHidden: false,
  spacing: 2
};

export default TaStepper
