import { Paper, Stack, TextField, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import { memo } from "react"
import { Controller } from "react-hook-form"
import NumberFormat from "react-number-format"
import { helperTextStyles } from './TextField'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { fnDefineTheme, fnDefineThemeLabel } from './ThemeDefinition'

const onFocus = (e) => {
  try {
    e.target.select()
  } catch (error) { }
}

const TaNumberTextField = memo(({
  id = '', name = '', control,
  decimalScale = 2, label = '', value,
  fullWidth = true, disabled = false, onChange,
  textAlign = 'right', rules, helperText = '',
  fontSize, color, thousandSeparator = true,
  defaultValue, uniqueKey, errorIcon = false,
  onKeyDown = () => null, noUnderline = false, theme = 'ordinary',
  adornment, adornmentPosition = 'start', decimalSeparator = false,
  isNumericString = false, onBlur, allowLeadingZeros = false, adornmentWidth
}) => {

  const textfieldClasses = fnDefineTheme(theme, noUnderline, textAlign)
  const textfieldLabelClasses = fnDefineThemeLabel(theme)
  const helperTextClasses = helperTextStyles()

  return (
    <>
      {control
        ? <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field, fieldState: { error } }) => {
            return (<NumberFormat
              id={id}
              customInput={TextField}
              name={field.name}
              register={field}
              label={label}
              error={error !== undefined}
              helperText={errorIcon ? helperText : error?.message ?? helperText ? error?.message ?? helperText : helperText}
              fullWidth={fullWidth}
              onKeyDown={onKeyDown}
              onFocus={onFocus}
              onBlur={onBlur}
              disabled={disabled}
              allowLeadingZeros={allowLeadingZeros}
              isNumericString={isNumericString}
              onValueChange={(values) => field.onChange(values.floatValue)}
              variant='standard'
              InputProps={{
                id: `input:${id}`,
                classes: { input: textfieldClasses.input, underline: textfieldClasses.underline, focused: textfieldClasses.focused },
                startAdornment: adornmentPosition !== 'end' && <Stack direction='row' spacing={1}>{adornment}</Stack>,
                endAdornment: (errorIcon && error !== undefined)
                  ? <Tooltip open={true} sx={{
                    fontFamily: 'Archivo',
                    fontSize: '11pt',
                    color: 'var(--main-text-color0)'
                  }} title={error?.message ?? ''}>
                    <InfoIcon
                      sx={{ color: 'var(--main-bg-color9)' }}
                    />
                  </Tooltip>
                  : adornmentPosition === 'end' && <Paper id={'endAdormentWrapper'} square={true} elevation={0} sx={{
                    display: 'flex', margin: '5px', alignItems: 'flex-end',
                    width: adornmentWidth, backgroundColor: 'transparent', fontFamily: 'Archivo',
                    fontSize: '11pt', color: 'var(--main-text-color0)'
                  }
                  }>{adornment}</Paper>,
              }}
              InputLabelProps={{
                classes: { root: textfieldLabelClasses.root, focused: textfieldLabelClasses.focused, },
                shrink: true,
              }}
              FormHelperTextProps={{
                classes: { root: helperTextClasses.root, disabled: helperTextClasses.disabled }
              }}
              thousandSeparator={thousandSeparator}
              decimalScale={decimalScale}
              value={field.value}
              displayType={"input"}
            />)
          }}
        />
        : <NumberFormat
          id={id}
          name={name}
          customInput={TextField}
          label={label}
          helperText={helperText}
          fullWidth={fullWidth}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          isNumericString={isNumericString}
          allowLeadingZeros={allowLeadingZeros}
          onValueChange={(values) => onChange && onChange(name, values.floatValue, uniqueKey)}
          variant='standard'
          InputProps={{
            id: `input:${id}`,
            classes: { input: textfieldClasses.input, underline: textfieldClasses.underline, focused: textfieldClasses.focused },
            startAdornment: adornmentPosition !== 'end' && <Stack direction='row' spacing={1}>{adornment}</Stack>,
            endAdornment: adornmentPosition === 'end' && <div>{adornment}</div>
          }}
          InputLabelProps={{
            classes: { root: textfieldLabelClasses.root, focused: textfieldLabelClasses.focused, },
            shrink: true,
          }}
          FormHelperTextProps={{
            classes: { root: helperTextClasses.root, disabled: helperTextClasses.disabled }
          }}
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          value={value}
          displayType={"input"}
        />
      }
    </>
  )
})

export default TaNumberTextField