import { memo } from 'react'
import TextField from '@mui/material/TextField'
import { helperTextStyles } from './TextField'
import moment from 'moment'
import { Paper, Stack, Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { fnDefineTheme, fnDefineThemeLabel } from './ThemeDefinition'

const onFocus = (e) => {
  try {
    e.target.select()
  } catch (error) { }
}

const TaTextField = memo(({
  label, helperText = '', fullWidth = true,
  type = 'text', type2, disabled = false,
  autoFocus = false, placeholder = '', fontSize,
  error, register, select, rules, children,
  onKeyDown, adornment, maxLength, rows,
  textAlign = 'left', adornmentPosition = '', id = '',
  customInputProps, customLowKeyInputProps, limitPastDate = '1900-01-01',
  limitFutureDate, name = '', value, noActiveUnderLine = false,
  onChange, readOnly = false, onBlur, errorIcon = false,
  theme = 'ordinary', noUnderline = false, multiline = false, adornmentWidth, noMargin = true
}) => {
  const textfieldClasses = fnDefineTheme(theme, noUnderline, textAlign, fontSize, noActiveUnderLine)
  const textfieldLabelClasses = fnDefineThemeLabel(theme)
  const helperTextClasses = helperTextStyles()

  const fnOnKeyDown = (event) => {
    // console.log('fnOnKeyDown', event.key, event.keyCode)//console.log(para saber si me devuelve el evento enter en un apk)
    onKeyDown && onKeyDown(event)
  }

  const fnOnBlur = (event) => onBlur && onBlur(event)

  const fnOnChange = (e) => onChange && onChange(e.target.name, e.target.value)
  const registerProps = register
    ? { ...register, inputRef: register.ref }
    : { name: name, onBlur: fnOnBlur, onChange: fnOnChange, value: value }

  return (
    <TextField
      {...registerProps}
      id={id}
      variant={'standard'}
      label={label}
      helperText={!errorIcon && typeof error === 'string' ? error : helperText}
      multiline={multiline}
      maxRows={multiline ? 3 : 1}
      fullWidth={fullWidth}
      autoFocus={autoFocus}
      type={type}
      rows={rows}
      onFocus={onFocus}
      autoComplete={type === 'password' ? 'new-password' : 'off'}
      disabled={disabled}
      SelectProps={(error !== undefined && errorIcon) ? { IconComponent: '' } : undefined}
      error={typeof (error) === 'string'}
      placeholder={placeholder}
      InputProps={(customInputProps !== undefined) ? customInputProps : {
        id: `input:${id}`,
        classes: { input: textfieldClasses.input, underline: textfieldClasses.underline, focused: textfieldClasses.focused },
        startAdornment: adornmentPosition !== 'end' && (adornment) &&
          <Paper id={'startAdormentWrapper'} square={true} elevation={0} sx={{
            display: 'flex', margin: (noMargin) ? '0px' : '5px', alignItems: 'flex-end',
            width: adornmentWidth, backgroundColor: 'transparent', fontFamily: 'Archivo',
            fontSize: '11pt', color: 'var(--main-text-color0)'
          }
          }>{adornment}</Paper>,
        endAdornment: errorIcon
          ? error !== undefined && <Tooltip open={true} sx={{ fontFamily: 'Archivo', fontSize: '11pt', color: 'var(--main-text-color0)', }} title={error ?? ''}>
            <InfoIcon sx={{ color: 'var(--main-bg-color9)' }} />
          </Tooltip>
          : adornmentPosition === 'end' && (adornment) &&
          <Paper id={'endAdormentWrapper'} square={true} elevation={0} sx={{
            display: 'flex', margin: (noMargin) ? '0px' : '5px', alignItems: 'flex-end',
            width: adornmentWidth, backgroundColor: 'transparent', fontFamily: 'Archivo',
            fontSize: '11pt', color: 'var(--main-text-color0)'
          }
          }>{adornment}</Paper>,
      }}
      inputProps={
        customLowKeyInputProps
          ? customLowKeyInputProps
          : {
            maxLength: maxLength ?? 524288,
            min: type === 'number'
              ? '0'
              : type === 'date'
                ? moment(limitPastDate).format('YYYY-MM-DD')
                : undefined,
            max: type === 'date'
              ? moment(limitFutureDate ?? moment(new Date()).add({ years: 10 })).format('YYYY-MM-DD')
              : type2 === 'numberZip'
                ? '9999'
                : type2 === 'docId'
                  ? '9999999999'
                  : undefined,
            step: type === 'number'
              ? '1'
              : undefined,
            readOnly: readOnly,
          }
      }
      InputLabelProps={{
        classes: { root: textfieldLabelClasses.root, focused: textfieldLabelClasses.focused, },
        shrink: true,
      }}
      FormHelperTextProps={{
        classes: { root: helperTextClasses.root, disabled: helperTextClasses.disabled }
      }}
      select={select ?? false}
      rules={rules}
      onBlur={fnOnBlur}
      onKeyDown={fnOnKeyDown}>
      {children}
    </TextField >
  )
})

export default TaTextField 