import { Grid, ListItemButton, Paper } from '@mui/material'
import TaTypography from 'components/typography/TaTypography'
import { memo } from 'react'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { fnFormatNumb } from 'helper/FormatNumb'
import TaImg from 'components/img/TaImg'
import { fnCalculateHeights } from 'helper/fnCalculateHeights'
import useCurrencyFactor from 'contexts/Currency/hooks/useCurrencyFactor'

const GridItemDetail = memo(({
  classes: {
    layoutSelected, layout, header,
    content, footer
  },
  itemData, onFocus, onClick,
  onKeyDown, useImages, selectedKey,
  onHoverGridItem, gridHeight, onLeave
}) => {

  const { uniqueKey, itemName,
    isFav, currentPrice, descrip2,
    descrip3, itemId, iSpot,
    isUnit, exist, existU,
    urlImage, urlImage2, isHidden, visibilityType } = itemData
  const { fnCurrencyFactor } = useCurrencyFactor()
  
  return (<ListItemButton
    id={`GridItem:${uniqueKey}`}
    sx={{ padding: '0px' }}
    onFocus={() => onFocus(itemData)}
    onClick={() => onClick(itemData)}
    onMouseEnter={() => onHoverGridItem(itemData)}
    disableRipple={true}
    disableTouchRipple={true}
    onKeyDown={(e) => onKeyDown(e, itemData)}
    onMouseLeave={onLeave}>
    <Paper id={`paperGridItem: ${uniqueKey}`} elevation={0} square={true} classes={{ root: (selectedKey === uniqueKey) ? layoutSelected : layout }}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper id={`paperGridItemHeader: ${uniqueKey}`} elevation={0} square={true} classes={{ root: header }}>
            <Grid container alignItems={'center'}>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                {visibilityType === 0
                  ? (isFav) && <StarIcon sx={{ padding: '5px 0px 0px 0px', fontSize: '15pt', color: 'var(--main-bg-color1)' }} />
                  : visibilityType === 1
                    ? (isFav)
                      ? <StarIcon sx={{ padding: '5px 0px 0px 0px', fontSize: '15pt', color: 'var(--main-bg-color1)' }} />
                      : <StarBorderIcon sx={{ padding: '5px 0px 0px 0px', fontSize: '15pt', color: 'var(--main-bg-color1)' }} />
                    : (isHidden)
                      ? <VisibilityOffIcon sx={{ padding: '5px 0px 0px 0px', fontSize: '15pt', color: 'var(--main-bg-color1)' }} />
                      : <VisibilityIcon sx={{ padding: '5px 0px 0px 0px', fontSize: '15pt', color: 'var(--main-bg-color1)' }} />
                }
              </Grid>
              <Grid item xs={9} sm={9} md={9} lg={9} xl={9} align={'right'} >
                <TaTypography text={fnCurrencyFactor(currentPrice).amountLabel} fontSize={"12pt"} color={'var(--main-bg-color1)'} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper id={`paperGridItemContent: ${uniqueKey}`} elevation={0} square={true} classes={{ root: content }}>
            {!useImages && <Grid container alignItems={'center'} justifyContent={'center'}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
                <TaTypography text={itemName.toUpperCase()} fontSize={"10pt"} color={'var(--main-bg-color1)'} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
                <TaTypography text={descrip2.toUpperCase()} fontSize={"10pt"} color={'var(--main-bg-color1)'} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
                <TaTypography text={descrip3.toUpperCase()} fontSize={"10pt"} color={'var(--main-bg-color1)'} />
              </Grid>
            </Grid>}
            {useImages && <TaImg id={`gridItemImage: ${uniqueKey}`} url={urlImage} defaultUrl={urlImage2} inStyle={{
              paddingTop: '0px',
              marginLeft: 'auto',
              marginRight: 'auto',
              height: fnCalculateHeights([gridHeight, 20]),
              width: '100%',
            }} />}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper id={`paperGridItemFooter: ${uniqueKey}`} elevation={0} square={true} classes={{ root: footer }}>
            <Grid container alignItems={'center'} justifyContent={'center'}>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <TaTypography color={'var(--main-text-color1)'} fontSize={'10pt'} text={itemId} />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align={'right'}>
                <TaTypography color={'var(--main-text-color1)'} fontSize={'10pt'} text={`${iSpot} ${(isUnit === 1) ? fnFormatNumb(existU) : fnFormatNumb(exist)}`} />
              </Grid>
              {/* <Grid item xs={3} sm={3} md={3} lg={3} xl={3} align={'right'}>
                <TaTypography color={'var(--main-text-color1)'} fontSize={'10pt'} text={} />
              </Grid> */}
              {useImages && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'left'}>
                <TaTypography color={'var(--main-text-color1)'} fontSize={'10pt'} text={itemName} />
              </Grid>}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  </ListItemButton>)
})

export default GridItemDetail