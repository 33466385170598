import { ListItem } from '@mui/material'
import { listItemStyles } from './styles/ListStyles'
import { memo } from 'react'
import PropTypes from 'prop-types'

const TaListItem = memo(({ id, children, x, onClickItem = () => null, selected, height, orderList = false,
    fontSize = '12pt', disabled = false, isAlternativeColor = false }) => {

    const classes = listItemStyles({ height, orderList, fontSize, isAlternativeColor })
    const fnClickItem = () => {
        return (disabled || selected) ? null : onClickItem(x)
    }

    return (
        <ListItem
            id={id}
            disabled={disabled}
            classes={{ root: classes.root, selected: classes.selected, disabled: classes.disabled }}
            selected={selected}
            onClick={fnClickItem}>
            {children}
        </ListItem >
    )
})

TaListItem.propTypes = {
    children: PropTypes.any.isRequired,
    x: PropTypes.object,
    onClickItem: PropTypes.func,
    selected: PropTypes.bool,
};

TaListItem.defaultProps = {
    selected: false,
};

export default TaListItem