import { Grid } from '@mui/material'
import { useState, useEffect } from 'react'
import Logo from 'proyectImages/SimplitPOS01.png'
import useDinamicHeights from "helper/useDinamicHeights"
import { CATALOG } from 'constants/appRoutes'
import { StringParam, useQueryParam } from "use-query-params"
import { TaSleep } from "functions/Sleep"
import Loader from "components/loader/Loader"
import TaPaper from "components/paper/TaPaper"
import TaImg from "components/img/TaImg"
import { useHistory } from 'react-router-dom'
const styleTutorial = (height, isTablet, isMobile) => ({
  tutorialMainContainerStyle: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  paperImageCenter: {
    width: isMobile ? '100%' : isTablet ? '95%' : '75%'
  },
  stepperContainerStyle: height - 255,
  logoSpLayoutStyle: {
    margin: isMobile ? '25px 0px 12px 0px' : '50px 0px 25px 0px',
    // height: isTablet ? height - 297 : height - 338,
  },
  logoSpLayoutStyleLoading: {
    height: isTablet ? height - 297 : height - 338
  },
})

const Tutorial = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false);
  const [customer] = useQueryParam('customer', StringParam)
  const [phone] = useQueryParam('phone', StringParam)

  const {
    paperImageCenter, tutorialMainContainerStyle,logoSpLayoutStyleLoading
  } = useDinamicHeights(styleTutorial)

  useEffect(() => {
    const checkTutorial = async () => {
      setIsLoading(true)
      await TaSleep(2000)
      setIsLoading(false)
      history.push(CATALOG, { customer: customer, phone: phone, })
    }
    checkTutorial();
  }, [])

  return (
    <Grid id={'container1'} container direction="row" justifyContent="center" alignItems="center" sx={tutorialMainContainerStyle}>
      <Grid item xs={11} sm={9} md={7} lg={5} xl={9} align="center" >
        <TaPaper elevation={2} Content={
          <Grid id={'container2'} container direction="row" justifyContent="center" alignItems="center" sx={logoSpLayoutStyleLoading}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center"  >
              <TaImg inStyle={paperImageCenter} url={Logo} />
            </Grid>
            <Loader isLoading={isLoading} />
          </Grid>
        } />
      </Grid>
    </Grid >
  )
}

export default Tutorial
