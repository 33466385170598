import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { LanguageProvider } from './contexts/Language/LanguageContext'
import { SnackbarProvider } from 'contexts/Snackbar/SnackbarContext'
import Tutorial from './views/Tutorial/'
import {
  TUTORIAL,CATALOG
} from 'constants/appRoutes'
import { QueryParamProvider } from 'use-query-params'
import TaSnackbar from 'components/snackbar/TaSnackbar'
import CatalogView from 'views/Catalog'

const App = () => {

  return (
    <Router>
      <LanguageProvider>
        <SnackbarProvider>
          <QueryParamProvider ReactRouterRoute={Route}>
            <Switch>
              <Route exact path={TUTORIAL} component={Tutorial} />
              <Route exact path={CATALOG} component={CatalogView} />
            </Switch>
            <TaSnackbar />
          </QueryParamProvider>
        </SnackbarProvider>
      </LanguageProvider>
    </Router >
  )
}

export default App
