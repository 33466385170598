import React from 'react'
import propTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Paper from '@mui/material/Paper'

// const paperStyles = makeStyles(() => ({
//   root: props => ({
//     position: (props.position) ? props.position : (props.drawer) ? 'absolute' : props.centerDiv ? 'fixed' : 'relative',
//     top: (props.centerDiv || props.drawer) && '50%',
//     left: (props.centerDiv) && '50%',
//     transform: props.centerDiv && 'translate(-50%, -50%)',
//     marginTop: (props.drawer) && '300px',
//     zIndex: (props.drawer) && '1000 !important',
//     background: (props.drawer) && 'rgb(255,255,255,0.0 )',
//     backgroundColor: (props.backgroundColor) ? props.backgroundColor : 'var(--main-bg-color0)',
//     width: (props.width) && props.width,
//     height: (props.height) && props.height,
//     padding: (props.padding) && props.padding,
//     drawer: (props.drawer) && props.drawer,
//     overflowY: (props.overflow) ? props.overflow : 'hidden !important',
//     overflowX: 'hidden',
//     bottom: (props.bottom) && props.bottom,
//     margin: (props.margin) && props.margin,
//     backgroundImage: (props.iso) && 'var(--bgImageIsotipo)',
//     backgroundRepeat: (props.iso) && 'no-repeat',
//     backgroundPosition: (props.iso) && 'center',
//     backgroundSize: (props.iso) && '65vh'
//   }),
// }))


const TaPaper = React.memo(({ id, Content, elevation, width, height, backgroundColor,
  padding, drawer, centerDiv, iso, squareBorder, overflow, position, bottom, margin }) => {

    return (
    <Paper
      id={id}
      square={squareBorder}
      sx={{
        position: (position) ? position : (drawer) ? 'absolute' : centerDiv ? 'fixed' : 'relative',
        top: (centerDiv || drawer) && '50%',
        left: (centerDiv) && '50%',
        transform: centerDiv && 'translate(-50%, -50%)',
        marginTop: (drawer) && '300px',
        zIndex: (drawer) && '1000 !important',
        background: (drawer) && 'rgb(255,255,255,0.0 )',
        backgroundColor: (backgroundColor) ? backgroundColor : 'var(--main-bg-color0)',
        width: (width) && width,
        height: (height) && height,
        padding: (padding) && padding,
        drawer: (drawer) && drawer,
        overflowY: (overflow) ? overflow : 'hidden !important',
        overflowX: 'hidden',
        bottom: (bottom) && bottom,
        margin: (margin) && margin,
        backgroundImage: (iso) && 'var(--bgImageIsotipo)',
        backgroundRepeat: (iso) && 'no-repeat',
        backgroundPosition: (iso) && 'center',
        backgroundSize: (iso) && '65vh'
      }}
      elevation={elevation}>
      {Content}
    </Paper>
  )
})

TaPaper.propTypes = {
  Content: propTypes.object.isRequired,
  elevation: propTypes.number,
  width: propTypes.any,
  height: propTypes.any,
  backgroundColor: propTypes.string,
  padding: propTypes.string,
  position: propTypes.string,
  bottom: propTypes.string,
  centerDiv: propTypes.bool,
  iso: propTypes.bool,
  squareBorder: propTypes.bool,
}

TaPaper.defaultProps = {
  elevation: 2,
  backgroundColor: '#fff',
  padding: '5px',
  overflow: 'auto',
  squareBorder: false,
  iso: false,
  centerDiv: false,
}

export default TaPaper